import React, { useEffect } from 'react';
import closeSvg from '../assets/close.svg';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import api from '../helpers/api'
import { Toast } from 'flowbite-react';
import { Bounce, toast } from 'react-toastify';
import { encrypt, decrypt } from '../utils/cryptography';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { decryption, encryption } from '../cipher/cipher';



const CompanyForm = ({ setCustomerModelStatus, customerModelStatus, status, id, handleSubmitEditForm }) => {

    let navigate = useNavigate()

    const companySchema = object().shape({
        companyName: string()
            .required('required')
            .max(50, 'Must be 50 characters or less')
            .matches(/^[A-Za-z0-9\s]+$/, 'Must only contain letters, numbers, and spaces'),
        contactPerson:
            string()
                .required('required')
                .max(30, 'Must be 30 characters or less')
                .matches(/^[A-Za-z\s]+$/, 'must be only contain letters and spaces'),
        email:
            string()
                .required('required')
                .email('Invalid email address'),
        phone:
            string()
                .required('required')
                .matches(/^[0-9]+$/, 'Phone number must only contain digits')
                .min(8, 'Phone number must be at least 8 digits')
                .max(15, 'Phone number must be at most 15 digits')
    });

    const handleSubmitForm = async (values) => {
        try {
            let encryptData = await encryption(values)
            let response = await api.post('/customer/add', { data: encryptData });

            if (response.status === 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {

        const updateForm = async () => {
            let encryptData = await encryption({ userId: localStorage.getItem('userId'), id: id })
            let response = await api.post('/customer', { data: encryptData });

            if (response.status == 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;

                formik.setFieldValue('companyName', response.data.companyName);
                formik.setFieldValue('contactPerson', response.data.contactPerson);
                formik.setFieldValue('phone', response.data.phone);
                formik.setFieldValue('email', response.data.email);
            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')

            }
        };

        if (status) {
            if (status === 'edit') {
                if (id) {
                    updateForm();
                }
            }
        }
    }, [customerModelStatus])


    const formik = useFormik({
        initialValues: {
            companyName: '',
            contactPerson: '',
            phone: '',
            email: ''
        },
        validationSchema: companySchema,
        onSubmit: values => {

            if (id) {
                handleSubmitEditForm(values, id);
                formik.resetForm();
                setCustomerModelStatus(!customerModelStatus);
                setTimeout(() => {
                    window.location.reload();
                }, 100)
            } else {
                handleSubmitForm({ ...values, tz: "Asia/Calcutta", userId: localStorage.getItem('userId') })
                formik.resetForm();
                setCustomerModelStatus(!customerModelStatus);
                setTimeout(() => {
                    window.location.reload();
                }, 100)
            }
        },
    });

    return (
        <div className=''>
            <div className='text-center mt-12 mb-4 font-bold'>{id ? "Edit" : "Add New"} Customer</div>
            <div className="mx-auto w-[80%]">
                <form onSubmit={formik.handleSubmit}>
                    <div className='mt-4'>
                        <label>Company's name <span className='text-[#FF0000]'>*</span></label>
                        <input type="text" name='companyName' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Type the list name here..." onChange={formik.handleChange} value={formik.values.companyName} />
                        <span className={`${formik.errors.companyName && formik.touched.companyName ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.companyName && formik.touched.companyName ? formik.errors.companyName : '\u00A0'}</span>
                    </div>
                    <div className='mt-0'>
                        <label>Contact Person <span className='text-[#FF0000]'>*</span></label>
                        <input type="text" name='contactPerson' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Type the list name here..." onChange={formik.handleChange} value={formik.values.contactPerson} />
                        <span className={`${formik.errors.contactPerson && formik.touched.contactPerson ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.contactPerson && formik.touched.contactPerson ? formik.errors.contactPerson : '\u00A0'}</span>
                    </div>
                    <div className='mt-0'>
                        <label>Phone number <span className='text-[#FF0000]'>*</span></label>
                        <input type="text" name='phone' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Type the list name here..." onChange={formik.handleChange} value={formik.values.phone} />
                        <span className={`${formik.errors.phone && formik.touched.phone ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.phone && formik.touched.phone ? formik.errors.phone : '\u00A0'}</span>
                    </div>
                    <div className='mt-0'>
                        <label>Email address <span className='text-[#FF0000]'>*</span></label>
                        <input type="text" name='email' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Type the list name here..." onChange={formik.handleChange} value={formik.values.email} />
                        <span className={`${formik.errors.email && formik.touched.email ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.email && formik.touched.email ? formik.errors.email : '\u00A0'}</span>
                    </div>
                    <div className='mt-1 flex justify-center'>
                        <button type="submit" className="text-white h-[38px] w-[183px] bg-black border border-[#000000] hover:bg-black font-medium rounded-3xl text-sm">Add Customer</button>
                    </div>
                </form>
            </div>
            <img src={closeSvg} alt='close-btn' className='absolute top-[20px] left-[20px] cursor-pointer' onClick={() => {
                setCustomerModelStatus(!customerModelStatus);
                formik.resetForm()
            }
            } />
        </div>
    )
}

export default CompanyForm