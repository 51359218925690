import React, { useEffect, useState } from 'react';
import api from '../helpers/api';
import { useNavigate } from 'react-router-dom';
import { decryption, encryption } from '../cipher/cipher';

const Sidebar = ({ setSidebar }) => {

    let navigate = useNavigate()

    let [form, setForm] = useState({
        orderBy: 0,
        male: false,
        female: false,
        instagram: false,
        tikTok: false,
        snapChat: false,
        x: false,
        youtube: false,
        ratings: [],
        countryId: null,
        cityId: null,
        interestId: null,
        categoryId: null,
    });

    let [lists, setLists] = useState({ city: [], country: [], category: [], interests: [] });

    // Make some api call for city, country, category, interests,

    const handleSidebarList = async () => {
        try {
            let encryptData = await encryption({ userId: localStorage.getItem('userId') });
            let city = await api.post('/cities/list', { data: encryptData });
            let category = await api.post('/categories/list', { data: encryptData });
            let country = await api.post('/countries/list', { data: encryptData });
            let interests = await api.post('/interests/list', { data: encryptData });

            city.data = await decryption(city.data);
            category.data = await decryption(category.data);
            country.data = await decryption(country.data);
            interests.data = await decryption(interests.data);

            if (city.status == 200 && category.status == 200 && country.status == 200 && interests.status == 200) {
                setLists((preVal) => ({
                    ...preVal,
                    city: city.data,
                    country: country.data,
                    category: category.data,
                    interests: interests.data,
                }));
            } else {
                localStorage.clear();
                navigate('/login')
            }
        }
        catch (err) {
            console.log(err);

        }
    }

    useEffect(() => {
        handleSidebarList();
    }, []);

    const handlePlatformRatings = (value, checked) => {
        if (checked) {
            setForm((preVal) => ({
                ...preVal,
                ratings: [...preVal.ratings, Number(value)],
            }));
        } else {
            let index = form.ratings.findIndex(val => val == value)
            setForm((prevState) => ({
                ...prevState,
                ratings: prevState.ratings.filter((_, i) => i !== index),
            }));
        }
    }

    const handleFilterApply = () => {
        setSidebar(form)
    };

    const handleClearFilter = () => {
        setForm((preVal) => ({
            ...preVal,
            instagram: false,
            instagram: false,
            tikTok: false,
            snapChat: false,
            x: false,
            youtube: false,
            ratings: [],
            orderBy: null,
            countryId: null,
            cityId: null,
            interestId: null,
            categoryId: null,
            male: false,
            female: false
        }));

        window.location.reload();

    };

    const handleClearLocalStorage = () => {
        localStorage.clear();
        navigate('/login')
    }

    return (
        <div className='bg-white border border-r-[#EAEAEA] w-[250px] fixed top-[145px] xl:top-[110px] 2xl:top-[80px] left-0 bottom-0 [&::-webkit-scrollbar]:[width:2px] [&::-webkit-scrollbar-thumb]:bg-[#f15625] [&::-webkit-scrollbar-thumb]:rounded-lg overflow-y-auto'>
            <div className="block w-4/5 mt-6 mx-auto">
                <div className='font-bold text-lg pb-2 pl-3'>Sort</div>
                <div className="relative inline-flex w-full">
                    <svg className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http:www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#000000" fillRule="nonzero" /></svg>
                    <select className="border border-black w-full rounded-full text-black h-10 pl-5 pr-10 focus:outline-none appearance-none" onChange={(e) => setForm((preVal) => ({ ...preVal, orderBy: Number(e.target.value) }))} value={form.orderBy != null ? form.orderBy : 'default_value'}>
                        <option value={'default_value'} selected hidden>Newest First</option>
                        <option value={0}>Newest first</option>
                        <option value={1}>oldest first</option>
                    </select>
                </div>
            </div>

            <div className="block w-4/5 mt-4 mx-auto">
                <div className='font-bold text-lg pb-2 pl-3'>Location</div>
                <div className="relative inline-flex w-full mb-5">
                    <svg className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http:www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#000000" fillRule="nonzero" /></svg>
                    <select className="border border-black w-full rounded-full text-black h-10 pl-5 pr-10 focus:outline-none appearance-none" onChange={(e) => setForm((preVal) => ({ ...preVal, countryId: Number(e.target.value) }))} value={form.countryId != null ? form.countryId : 'default_value'}>
                        <option value={'default_value'} selected hidden>Country</option>
                        {lists.country.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </select>
                </div>
                <div className="relative inline-flex w-full">
                    <svg className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http:www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#000000" fillRule="nonzero" /></svg>
                    <select className="border border-black w-full rounded-full text-black h-10 pl-5 pr-10 focus:outline-none appearance-none" onChange={(e) => setForm((preVal) => ({ ...preVal, cityId: Number(e.target.value) }))} value={form.cityId != null ? form.cityId : 'default_value'}>
                        <option value={'default_value'} selected hidden>City</option>
                        {lists.city.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="block w-4/5 mt-6 mx-auto">
                <div className='font-bold text-lg pb-2 pl-3'>Category</div>
                <div className="relative inline-flex w-full">
                    <svg className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#000000" fillRule="nonzero" /></svg>
                    <select className="border border-black w-full rounded-full text-black h-10 pl-5 pr-10 focus:outline-none appearance-none" onChange={(e) => setForm((preVal) => ({ ...preVal, categoryId: Number(e.target.value) }))} value={form.categoryId != null ? form.categoryId : 'default_value'}>
                        <option value={'default_value'} selected hidden>Select category</option>
                        {lists.category.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="block w-4/5 mt-6 mx-auto">
                <div className='font-bold text-lg pb-2 pl-3'>Interests</div>
                <div className="relative inline-flex w-full">
                    <svg className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#000000" fillRule="nonzero" /></svg>
                    <select className="border border-black w-full rounded-full text-black h-10 pl-5 pr-10 focus:outline-none appearance-none" onChange={(e) => setForm((preVal) => ({ ...preVal, interestId: Number(e.target.value) }))} value={form.interestId != null ? form.interestId : 'default_value'}>
                        <option value={'default_value'} selected hidden>Select interests</option>
                        {lists.interests.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="block w-4/5 mt-6 mx-auto">
                <div className='font-bold text-lg pb-2 pl-3 '>Gender</div>

                <div className="flex items-center mb-2 ml-3">
                    <input id="default-checkbox" type="checkbox" checked={form.male} value="male" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded accent-black" onChange={(e) => setForm((preVal) => ({ ...preVal, male: e.target.checked }))} />
                    <label /* htmlFor="default-checkbox" */ className="ms-2 text-sm font-medium text-gray-900 ">Male</label>
                </div>
                <div className="flex items-center mb-2 ml-3">
                    <input id="default-checkbox" type="checkbox" value="female" checked={form.female} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded accent-black" onChange={(e) => setForm((preVal) => ({ ...preVal, female: e.target.checked }))} />
                    <label /* htmlFor="default-checkbox" */ className="ms-2 text-sm font-medium text-gray-900 ">Female</label>
                </div>
            </div>

            <div className="block w-4/5 mt-6 mx-auto">
                <div className='font-bold text-lg pb-2 pl-3 '>Platform</div>

                <div className="flex items-center mb-2 ml-3">
                    <input id="default-checkbox" type="checkbox" checked={form.instagram} value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded accent-black" onChange={(e) => setForm((preVal) => ({ ...preVal, instagram: e.target.checked }))} />
                    <label /* htmlFor="default-checkbox" */ className="ms-2 text-sm font-medium text-gray-900 ">Instagram</label>
                </div>
                <div className="flex items-center mb-2 ml-3">
                    <input id="default-checkbox" type="checkbox" checked={form.tikTok} value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded accent-black" onChange={(e) => setForm((preVal) => ({ ...preVal, tikTok: e.target.checked }))} />
                    <label /* htmlFor="default-checkbox" */ className="ms-2 text-sm font-medium text-gray-900 ">TikTok</label>
                </div>
                <div className="flex items-center mb-2 ml-3">
                    <input id="default-checkbox" type="checkbox" checked={form.snapChat} value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded accent-black" onChange={(e) => setForm((preVal) => ({ ...preVal, snapChat: e.target.checked }))} />
                    <label /* htmlFor="default-checkbox" */ className="ms-2 text-sm font-medium text-gray-900 ">Snapchat</label>
                </div>
                <div className="flex items-center mb-2 ml-3">
                    <input id="default-checkbox" type="checkbox" checked={form.x} value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded accent-black" onChange={(e) => setForm((preVal) => ({ ...preVal, x: e.target.checked }))} />
                    <label /* htmlFor="default-checkbox" */ className="ms-2 text-sm font-medium text-gray-900 ">X</label>
                </div>
                <div className="flex items-center mb-2 ml-3">
                    <input id="default-checkbox" type="checkbox" checked={form.youtube} value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded accent-black" onChange={(e) => setForm((preVal) => ({ ...preVal, youtube: e.target.checked }))} />
                    <label /* htmlFor="default-checkbox" */ className="ms-2 text-sm font-medium text-gray-900 ">Youtube</label>
                </div>
            </div>
            <div className="block w-4/5 mt-6 mx-auto">
                <div className='font-bold text-lg pb-2 pl-3 '>Rating</div>

                <div className="flex items-center mb-2 ml-3">
                    <input id="default-checkbox" type="checkbox" checked={(form.ratings.includes(1)) === true ? true : false} value="1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded accent-black" onChange={(e) => handlePlatformRatings(e.target.value, e.target.checked)} />
                    <label /* htmlFor="default-checkbox" */ className="ms-2 text-sm font-medium text-gray-900 ">1 Star</label>
                </div>
                <div className="flex items-center mb-2 ml-3">
                    <input id="default-checkbox" type="checkbox" checked={(form.ratings.includes(2)) === true ? true : false} value="2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded accent-black" onChange={(e) => handlePlatformRatings(e.target.value, e.target.checked)} />
                    <label /* htmlFor="default-checkbox" */ className="ms-2 text-sm font-medium text-gray-900 ">2 Stars</label>
                </div>
                <div className="flex items-center mb-2 ml-3">
                    <input id="default-checkbox" type="checkbox" checked={(form.ratings.includes(3)) === true ? true : false} value="3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded accent-black" onChange={(e) => handlePlatformRatings(e.target.value, e.target.checked)} />
                    <label /* htmlFor="default-checkbox" */ className="ms-2 text-sm font-medium text-gray-900 ">3 Stars</label>
                </div>
                <div className="flex items-center mb-2 ml-3">
                    <input id="default-checkbox" type="checkbox" checked={(form.ratings.includes(4)) === true ? true : false} value="4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded accent-black" onChange={(e) => handlePlatformRatings(e.target.value, e.target.checked)} />
                    <label /* htmlFor="default-checkbox" */ className="ms-2 text-sm font-medium text-gray-900 ">4 Stars</label>
                </div>
                <div className="flex items-center mb-2 ml-3">
                    <input id="default-checkbox" type="checkbox" checked={(form.ratings.includes(5)) === true ? true : false} value="5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded accent-black" onChange={(e) => handlePlatformRatings(e.target.value, e.target.checked)} />
                    <label /* htmlFor="default-checkbox" */ className="ms-2 text-sm font-medium text-gray-900 ">5 Stars</label>
                </div>
            </div>
            <div className="block w-4/5 mt-6 mx-auto">
                <button type="button" className="text-white bg-black border w-full border-[#000000] hover:bg-black font-medium rounded-lg text-sm px-4 py-3 " onClick={handleFilterApply}>Apply</button>
                <button type="button" className="text-[#f15625] bg-white border w-full border-[#ffffff] hover:bg-white font-medium rounded-lg text-sm px-4 py-3 " onClick={handleClearFilter}>Clear Filter</button>
            </div>
            <div className="block w-4/5 my-3 mx-auto">
                <button type="button" className="text-[#000000] bg-white border w-full border-[#ffffff] hover:bg-white font-medium rounded-lg text-sm px-4 py-3 " onClick={() => handleClearLocalStorage()}>Log out</button>
            </div>

        </div>
    )
}

export default Sidebar