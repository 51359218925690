import { createContext, useRef, useState } from 'react';

export const CanvasContext = createContext();

export const MContext = ({ children }) => {
    const listId = useRef(null);
    const [rightBar, setRightBar] = useState(false);
    const [listArr, setListArr] = useState([]);
    const [dataSource, setDataSource] = useState([]);

    // delete Model
    // const [deleteModel, setDeleteModel] = useState(false);
    // const id = useRef(null);

    return (
        <CanvasContext.Provider value={{
            rightBar,
            setRightBar,
            listId,
            listArr,
            setListArr,
            dataSource,
            setDataSource
            // deleteModel,
            // setDeleteModel,
            // id,
        }}>
            {children}
        </CanvasContext.Provider>
    )
}
