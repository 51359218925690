import React, { useContext, useEffect, useRef, useState } from 'react'
import Breadcrumb from '../components/Breadcrumb'
import InfluencerCard from '../components/InfluencerCard'
import InfiniteScroll from "react-infinite-scroll-component";
import Model from '../components/Model';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import api from '../helpers/api';
import { useNavigate, useParams } from 'react-router-dom';

import RightSidebar from "react-sidebar";
import SelectInfluencerCard from '../components/SelectInfluencerCard';
import { CanvasContext } from '../context/MainContext';
import { decryption, encryption } from '../cipher/cipher';


const ListHome = () => {
    let { setRightBar, rightBar, listId, listArr, setListArr, dataSource, setDataSource } = useContext(CanvasContext);
    let { id } = useParams();

    let navigate = useNavigate();

    const page = useRef(1);
    const [hasMore, setHasMore] = useState(true);

    const [sideBar, setSidebar] = useState({});
    const [search, setSearch] = useState('');
    const [rightSidebar, setRightSidebar] = useState(false);

    // effect getting influencer lists
    useEffect(() => {
        let token = localStorage.getItem('token');
        listId.current = id;
        if (!token) {
            localStorage.clear();
            navigate('/login');
        }
        // setListArr([]);
        const handleListArr = async () => {
            try {
                let encryptData = await encryption({ listId: id, userId: localStorage.getItem('userId') });
                let response = await api.post('/influencers/list/all', { data: encryptData });
                if (response.status == 200) {
                    let decryptData = await decryption(response.data);
                    console.log('erdsath was called', decryptData);

                    setListArr(decryptData)
                }
                else {
                    localStorage.clear();
                    window.location.href = '/';
                }
            }
            catch (err) {
                console.log(err);
            }
        }

        handleListArr();
    }, [])

    const handleInitialCards = async () => {
        try {
            let encryptData = await encryption({
                search: search ? search : null,
                startDate: null,
                endDate: null,
                offset: 0,
                limit: 10,
                listId: id,
                sort: sideBar?.orderBy,
                countryId: sideBar?.countryId ? sideBar.countryId : null,
                cityId: sideBar?.cityId ? sideBar.cityId : null,
                categoryId: sideBar?.categoryId ? sideBar.categoryId : null,
                interestId: sideBar?.interestId,
                gender: (sideBar?.male && sideBar.female) ? [0, 1] : (!(sideBar?.male) && sideBar.female) ? [1] : ((sideBar?.male) && !sideBar?.female) ? [0] : null,
                instagram: sideBar?.instagram ? sideBar.instagram : null,
                tiktok: sideBar?.tiktok ? sideBar.tiktok : null,
                snapChat: sideBar?.snapChat ? sideBar.snapChat : null,
                x: sideBar?.x ? sideBar.x : null,
                youtube: sideBar?.youtube ? sideBar.youtube : null,
                ratings: sideBar?.ratings,
                userId: localStorage.getItem('userId')
            })
            let response = await api.post('/influencers', { data: encryptData });


            if (response.status == 200) {
                let decryptData = await decryption(response.data);
                let selectedItems = [];
                if (listArr.length) {
                    selectedItems = decryptData.result.map(item => {
                        const isSelected = listArr.some(obj => obj.id === item.id);
                        return isSelected ? { ...item, listSummaryStatus: true } : item;
                    });
                }
                decryptData.result = (selectedItems.length === 0) ? decryptData.result : selectedItems;
                response.data = decryptData;

                setDataSource(response.data.result)
            }

            if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        handleInitialCards();
    }, [])


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        page.current = 1;
        handleInitialCards();
    }, [search])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        page.current = 1;
        handleInitialCards();
    }, [sideBar])

    const fetchMoreData = async () => {
        page.current = page.current + 1;
        const newOffset = (page.current - 1) * 10;

        let encryptData = await encryption({
            search: search ? search : null,
            startDate: null,
            endDate: null,
            offset: newOffset,
            limit: 10,
            listId: id,
            sort: sideBar?.orderBy,
            countryId: sideBar?.countryId ? sideBar.countryId : null,
            cityId: sideBar?.cityId ? sideBar.cityId : null,
            categoryId: sideBar?.categoryId ? sideBar.categoryId : null,
            interestId: sideBar?.interestId,
            gender: null,
            instagram: sideBar?.instagram ? sideBar.instagram : null,
            tiktok: sideBar?.tiktok ? sideBar.tiktok : null,
            snapChat: sideBar?.snapChat ? sideBar.snapChat : null,
            x: sideBar?.x ? sideBar.x : null,
            youtube: sideBar?.youtube ? sideBar.youtube : null,
            ratings: sideBar?.ratings ? sideBar?.ratings : null,
            userId: localStorage.getItem('userId')
        })

        let response = await api.post('/influencers', { data: encryptData });


        if (response.status == 603) {
            localStorage.clear();
            navigate('/login')
        }

        let decryptData = await decryption(response.data);

        let selectedItems = [];
        if (listArr.length) {
            selectedItems = decryptData.result.map(item => {
                const isSelected = listArr.some(obj => obj.id === item.id);
                return isSelected ? { ...item, listSummaryStatus: true } : item;
            });
        }
        decryptData.result = (selectedItems.length === 0) ? decryptData.result : selectedItems;
        response.data = decryptData;

        /* Use the below logic  */
        if (response.data.result.length < 10) {

        }

        setTimeout(() => {
            setDataSource(dataSource.concat(response.data.result))
        }, 500);
    };

    const handleAddInfluencerToList = async (info) => {
        setDataSource(prevItems =>
            prevItems.map(item =>
                item.id === info.id ? info : item
            )
        )
        if (info?.listSummaryStatus === false) {
            setListArr(prevArray => {
                if (prevArray.some(item => item.id === info.id)) {
                    return prevArray.filter(item => item.id !== info.id);
                }
                return prevArray; // Return the original array if id does not exist
            });
        }

        if (info?.listSummaryStatus) {
            setListArr(prevArray => {
                if (!prevArray.some(item => item.id === info.id)) {
                    return [...prevArray, info];
                }
                return prevArray; // Return the original array if id exists
            });
        }
    };

    return (
        <>
            <Header setSearch={setSearch} />
            <Sidebar setSidebar={setSidebar} />
            <main className='mt-[145px] xl:mt-[110px] 2xl:mt-[80px] ml-[250px] pt-[30px] px-[80px] h-[calc(100vh-145px)] xl:h-[calc(100vh-110px)] 2xl:h-[calc(100vh-80px)]'>
                <Breadcrumb title={"All Influencers"} currentPage={[{ label: "Lists", class: null, link: '/lists' }, { label: "Influencer", class: "active", }]} />
                {
                    dataSource.length > 0 ?
                        <InfiniteScroll
                            dataLength={dataSource.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                        // loader={<p>Loading...</p>}
                        >
                            <div className='flex flex-wrap w-full gap-3 pb-[30px]'>                               {
                                dataSource.map((info, index) => {
                                    return (
                                        <SelectInfluencerCard info={info} key={index} id={id} handleAddInfluencerToList={handleAddInfluencerToList} />
                                    )
                                })
                            }
                            </div>
                        </InfiniteScroll>
                        :
                        <div className='flex justify-center items-center font-bold h-2/3'>There are no influencers</div>
                }
            </main>
        </>
    )
}

export default ListHome;