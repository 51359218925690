import React, { useEffect, useRef, useState } from 'react';
import closeSvg from '../assets/close.svg';
import { useFormik } from 'formik';
import { number, object, string } from 'yup';

import listSvg from '../assets/list.svg';
import api from '../helpers/api';
import { useNavigate } from 'react-router-dom';

import { encryption, decryption } from '../cipher/cipher';



const AddToListForm = ({ setListModelStatus, listModelStatus, id }) => {

    const [companySelect, setCompanySelect] = useState('default-value');
    const [company, setCompany] = useState([]);

    let navigate = useNavigate();

    useEffect(() => {

        const handleCompanyList = async () => {
            try {
                let encryptData = await encryption({ userId: localStorage.getItem('userId'), influencerId: id });
                let response = await api.post('/lists/all', { data: encryptData });

                if (response.status == 200) {
                    let decryptData = await decryption(response.data);
                    response.data = decryptData;
                    setCompany(response.data)
                }
                else if (response.status == 603) {
                    localStorage.clear();
                    navigate('/login')
                }
            }
            catch (err) {
                console.log(err);
            }
        };

        handleCompanyList();

    }, [listModelStatus]);

    const companySchema = object().shape({
        listId: number()
            .required('required')
    });

    const handleListSubmit = async (values) => {
        try {
            let request = {
                influencerId: id,
                listId: values.listId,
                userId: localStorage.getItem('userId'),
                tz: "Asia/Calcutta"
            }
            let encryptData = await encryption(request);
            let response = await api.post('/influencer/list/add', { data: encryptData });

            if (response.status == 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                setListModelStatus(!listModelStatus);
                setCompanySelect('default-value')
                formik.resetForm()
            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
        }
        catch (err) {
            console.log(err);
        }
    }


    const formik = useFormik({
        initialValues: {
            listId: null,
        },
        validationSchema: companySchema,
        onSubmit: values => {
            handleListSubmit(values);
        },
    });

    return (
        <div className='pt-12'>
            <div className='flex justify-center items-center flex-col'>
                <img src={listSvg} />
                <div className='pt-2 font-bold'>Add influencer to list</div>
                <form onSubmit={formik.handleSubmit} className='w-[80%]'>
                    <div className='mt-4'>
                        <label>Select list</label>
                        <select value={companySelect} id="countries" name='listId' className="bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none" onChange={(e) => {
                            setCompanySelect(e.target.value)
                            formik.setFieldValue('listId', Number(e.target.value));
                        }}>
                            <option value={'default-value'} selected hidden>Select list</option>
                            {
                                company.map((item, index) =>
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            }
                        </select>
                        <span className={`${formik.errors.listId ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.listId ? formik.errors.listId : '\u00A0'}</span>
                    </div>
                    <div className='mt-1 flex justify-center'>
                        <button type="submit" className="text-white h-[38px] w-[183px] bg-black border border-[#000000] hover:bg-black font-medium rounded-3xl text-sm">Submit</button>
                    </div>
                </form>
            </div >
            <img src={closeSvg} alt='close-btn' className='absolute top-[20px] left-[20px] cursor-pointer' onClick={() => {
                setListModelStatus(!listModelStatus);
                setCompanySelect('default-value')
                formik.resetForm()
            }
            } />
        </div >
    )
}

export default AddToListForm;