import Modal from 'react-awesome-modal';
import React, { useContext } from 'react'
import { CanvasContext } from '../context/MainContext';

const DeleteModel = ({ deleteModel, setDeleteModel, handleDelete }) => {

    // let { deleteModel, setDeleteModel, submitRef } = useContext(CanvasContext);

    return (
        <Modal visible={deleteModel} width="400" height="175" effect="fadeInUp" onClickAway={() => setDeleteModel(!deleteModel)}>
            <div className=" px-4 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg className="h-6 w-6 text-[#f15625]" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                        </svg>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Delete Item</h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">Are you sure you want to delete? This action is permanent. Please confirm to proceed.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button type="button" className="inline-flex w-full justify-center rounded-md bg-[#F15625] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto" onClick={() => handleDelete()}>Delete</button>
                <button type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" onClick={() => setDeleteModel(!deleteModel)}>Cancel</button>
            </div>
        </Modal >
    )
}

export default DeleteModel;