import React, { useEffect, useRef, useState } from 'react'
import Breadcrumb from '../components/Breadcrumb';
import arrowSvg from '../assets/arrow.svg';
import whiteArrow from '../assets/whiteArrow.svg';
import upload from '../assets/upload.svg';
import { json, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import { object, string, number } from 'yup';

// react select component
import AsyncSelector from "react-select/async";
import Select, { components } from "react-select";
import api from '../helpers/api';

import Header from '../components/Header';
import { decryption, encryption } from '../cipher/cipher';


const InfluencerForm = () => {

    let { id } = useParams();

    let location = useLocation();

    let navigate = useNavigate();

    const imageRef = useRef();

    const [image, setImage] = useState(null);
    const [country, setCountry] = useState([]);
    const [city, setCity] = useState([]);
    const [category, setCategory] = useState([]);
    const [interests, setInterests] = useState([]);
    const [imageData, setImageData] = useState(null);
    const [search, setSearch] = useState('');
    const [selectedOption, setSelectedOption] = useState([]);

    // selector state handle
    const [genderSelect, setGenderSelect] = useState('default-value');
    const [categorySelect, setCategorySelect] = useState('default-value');
    const [citySelect, setCitySelect] = useState('default-value');
    // const [interestsSelect, setInterestsSelect] = useState('default-value');
    const [countrySelect, setCountrySelect] = useState('default-value');

    const handleCountries = async () => {
        try {
            let encryptData = await encryption({ userId: localStorage.getItem('userId') })
            let response = await api.post('/countries/list', { data: encryptData });

            if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }

            let decryptData = await decryption(response.data);
            response.data = decryptData;

            setCountry(response.data)
        }
        catch (err) {
            console.log(err);
        }
    };

    const handleCities = async () => {
        try {
            let encryptData = await encryption({ userId: localStorage.getItem('userId') })
            let response = await api.post('/cities/list', { data: encryptData });

            if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }

            let decryptData = await decryption(response.data);
            response.data = decryptData;

            setCity(response.data)
        }
        catch (err) {
            console.log(err);
        }
    };

    const handleInterests = async () => {
        try {
            let encryptData = await encryption({ userId: localStorage.getItem('userId') })
            let response = await api.post('/interests/list', { data: encryptData });

            if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }

            let decryptData = await decryption(response.data);
            response.data = decryptData;

            setInterests(response.data)
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleCategory = async () => {
        try {
            let encryptData = await encryption({ userId: localStorage.getItem('userId') })
            let response = await api.post('/categories/list', { data: encryptData });


            if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }

            let decryptData = await decryption(response.data);
            response.data = decryptData;

            setCategory(response.data)
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        handleCountries();
        handleCities();
        handleCategory();
        handleInterests()
    }, []);

    useEffect(() => {

    }, [search]);

    useEffect(() => {
        const handleInfluencerInfo = async () => {
            let encryptData = await encryption({ userId: localStorage.getItem('userId'), id: id })
            let response = await api.post('/influencer', { data: encryptData });

            if (response?.status == 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                let data = response.data;

                setGenderSelect(data?.gender);
                setCategorySelect(data?.categoryId);
                setCitySelect(data?.cityId);
                setCountrySelect(data?.countryId);
                setSelectedOption(data?.interestDetails);

                const ids = data?.interestDetails.map(item => item.id);
                console.log('ids', ids);

                formik.setFieldValue('interestIds', ids);
                formik.setFieldValue('gender', Number(data?.gender));
                formik.setFieldValue('categoryId', Number(data?.categoryId));
                formik.setFieldValue('countryId', Number(data?.countryId));
                formik.setFieldValue('cityId', Number(data?.cityId));

                formik.setFieldValue('fullName', data?.fullName);
                formik.setFieldValue('phone', (data?.phone) ? data?.phone : '');
                formik.setFieldValue('email', (data?.email) ? data?.email : '');
                formik.setFieldValue('instagram', data?.instagram ? data?.instagram : '');
                formik.setFieldValue('tiktok', data?.tiktok ? data?.tiktok : '');
                formik.setFieldValue('x', data?.x ? data?.x : '');
                formik.setFieldValue('youtube', data?.youtube ? data?.youtube : '');
                formik.setFieldValue('snapchat', data?.snapchat ? data?.snapchat : '');

                if (data?.imageUrl) {
                    setImage(global.config.API_URL + data?.imageUrl)
                }
                imageRef.current = data?.imageUrl;

                // formik.setFieldTouched('gender', true);
                // formik.setFieldTouched('categoryId', true);
                // formik.setFieldTouched('countryId', true);
                // formik.setFieldTouched('cityId', true);
                // formik.setFieldTouched('interestIds', true);
            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
        };
        if (id) {
            handleInfluencerInfo();
        }
    }, []);

    const [submitStatus, setSubmitStatus] = useState(null);

    const customStyles = {
        control: (provided, state) => ({ // class attribute : className=" css-i32vvf-control"
            ...provided,
            background: state.isFocused ? '#FAFAFA' : '#FAFAFA',
            display: 'flex',
            flexWrap: 'nowrap',
            borderColor: state.isFocused ? 'black' : 'black',
            borderRadius: '9px',
            // width: '7em'
        }),
        menu: (provided) => ({ // 'menu' is from the div class too.
            ...provided,
            background: 'white',
            // width: '4em'
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: '#000000',
            }
        },
    };


    const validationSchema = object().shape({
        gender: number().nullable(),
        categoryId: number().nullable(),
        // interestIds: number().nullable(),
        countryId: number().nullable(),
        cityId: number().nullable(),
        fullName: string()
            .max(50, 'Must be 50 characters or less')
            .matches(/^[A-Za-z0-9\s]+$/, 'Must only contain letters, numbers, and spaces'),
        email:
            string()
                .email('Invalid email address')
                .test(
                    'test-id',
                    'Email was already exist',
                    async function validateValue(value) {
                        try {
                            let request = {
                                tableName: "influencer",
                                columnName: "email",
                                value: value,
                                id: id ? id : null,
                                userId: localStorage.getItem('userId')
                            };
                            let encryptData = await encryption(request)
                            let response = await api.post('/unique/check', { data: encryptData })

                            if (response.status == 606)
                                return false;
                            else {
                                let decryptData = await decryption(response.data);
                                response.data = decryptData;
                                return true
                            }
                        } catch (error) {

                        }
                    }),
        phone:
            string()
                .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
                .test(
                    'test-phone',
                    'Phone was already exist',
                    async function validateValue(value) {
                        try {
                            let request = {
                                tableName: "influencer",
                                columnName: "phone",
                                value: value,
                                id: id ? id : null,
                                userId: localStorage.getItem('userId')
                            };
                            let encryptData = await encryption(request)
                            let response = await api.post('/unique/check', { data: encryptData })


                            if (response.status == 606)
                                return false;
                            else {
                                let decryptData = await decryption(response.data);
                                response.data = decryptData;
                                return true
                            }
                        } catch (error) {

                        }
                    }),
        instagram: string()
            .url('Invalid URL format'),
        tiktok: string()
            .url('Invalid URL format'),
        x: string()
            .url('Invalid URL format'),
        youtube: string()
            .url('Invalid URL format'),
        snapchat: string()
            .url('Invalid URL format'),
    });

    const handleUploadImage = async () => {
        try {
            const data = new FormData();
            const originalFileName = imageData.name; // Get the original file name
            const extension = originalFileName.split('.').pop(); // Extract the extension
            const newFileName = 'INF.' + extension; // Set the new file name

            // Create a new File object with the new name
            const renamedFile = new File([imageData], newFileName, { type: imageData.type });

            // Append the renamed file to FormData
            data.append('files', renamedFile);

            let response = await api.post('/image/single/upload', data);
            if (response.status == 200) {
                return response.data.imageUrl;
            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
            else {
                return null
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const handleSubmitForm = async (values) => {
        try {
            values.imageUrl = null;
            if (imageData) {
                let image = await handleUploadImage();
                values.imageUrl = image;
            }

            // we don't have a time so we should write like this for now       
            if (id) {
                values.id = Number(id);
                values.oldImageUrl = null;

                if (image == null) {
                    values.imageUrl = null;
                }
                if (global.config.API_URL + imageRef.current === image) {
                    values.imageUrl = imageRef.current;
                }
            }

            let url = id ? '/influencer/update' : '/influencer/add';
            let encryptData = await encryption(values)
            let response = await api.post(url, { data: encryptData });


            if (response.status == 603) {
                localStorage.clear();
                navigate('/login');
                return;
            }

            if (response.status == 200) {
                return response
            }

            let decryptData = await decryption(response.data);
            response.data = decryptData;
        }
        catch (err) {
            console.log(err);
        }
    }

    // formik for form handling

    const formik = useFormik({
        initialValues: {
            gender: null,
            categoryId: null,
            countryId: null,
            cityId: null,
            interestIds: null,


            fullName: '',
            phone: '',
            email: '',
            instagram: '',
            tiktok: '',
            x: '',
            youtube: '',
            snapchat: ''

        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // alert(JSON.stringify(values, null, 2));
            setGenderSelect('default-value')
            setCategorySelect('default-value')
            setCitySelect('default-value')
            setCountrySelect('default-value');
            setSelectedOption([]);

            values.xCount = null;
            values.youtubeCount = null;
            values.tiktokCount = null;
            values.snapchatCount = null;
            values.instagramCount = null;
            values.tz = "Asia/Calcutta";
            values.userId = localStorage.getItem('userId');

            for (const key in values) {
                if (values[key] === '') {
                    values[key] = null;
                }
                if (values[key] === 0) {
                    values[key] = null;
                }
            }

            if (!values.interestIds) {
                values.interestIds = [];
            }

            let response = await handleSubmitForm(values);


            setImage(null);
            setImageData(null);
            imageRef.current = null;

            formik.resetForm();

            if (submitStatus) {
                if (submitStatus == 'save') {
                    setSubmitStatus(null)
                }
            }

            if (response.status == 200) {
                window.location.href = '/'
            }
        },
    });

    const handleImageChange = event => {
        try {
            setImageData(event.target.files[0]);
            setImage(URL.createObjectURL(event.target.files[0]));
        }
        catch (err) {
            console.log(err);
        }
    };

    const ValueContainer = ({ children, getValue, ...props }) => {
        var values = getValue();
        var valueLabel = "";

        if (values.length > 0) valueLabel += props.selectProps.getOptionLabel(values[0]);
        if (values.length > 1) valueLabel += ' , ' + props.selectProps.getOptionLabel(values[1]);
        if (values.length > 2) valueLabel += ` & ${values.length - 2} more`;

        // Keep standard placeholder and input from react-select
        var childsToRender = React.Children.toArray(children).filter((child) => ['Input', 'DummyInput', 'Placeholder'].indexOf(child.type.name) >= 0);

        return (
            <components.ValueContainer {...props}>
                {!props.selectProps.inputValue && valueLabel}
                {childsToRender}
            </components.ValueContainer>
        );
    };

    return (
        <>
            <Header setSearch={setSearch} />
            <main className='mt-[145px] xl:mt-[110px] 2xl:mt-[80px] pt-[30px] px-[80px] h-[calc(100vh-145px)] xl:h-[calc(100vh-110px)] 2xl:h-[calc(100vh-80px)] w-full'>
                <form onSubmit={formik.handleSubmit}>
                    <div className='flex justify-center items-center'>
                        <div className='mb-16'>
                            {/* <Breadcrumb title={(location.pathname.substring(12, location.pathname.length - 2) === 'edit') ? "Edit Influencer" : "Add Influencer"} currentPage={(location.pathname.substring(12, location.pathname.length - 2) === 'edit') ? "Home/Edit influencer" : "Home/Add influencer"} /> */}
                            <Breadcrumb title={(location.pathname.substring(12, location.pathname.length - 2) === 'edit') ? "Edit Influencer" : "Add Influencer"} currentPage={[{ label: (location.pathname.substring(12, location.pathname.length - 2) === 'edit') ? "Edit Influencer" : "Add Influencer", class: "active", }]} />
                            <div className='flex gap-8'>

                                {/* About */}
                                <div className='w-[409px] h-[580px] border border-black rounded-3xl p-6 pt-8 py-3'>
                                    <div className='text-3xl font-bold'>About</div>

                                    <div className="max-w-sm mx-auto">
                                        <div className='mt-4'>
                                            <label>Gender</label>
                                            <select id="countries" name='gender' value={genderSelect} className="bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none" onChange={(e) => {
                                                setGenderSelect(e.target.value)
                                                formik.setFieldTouched('gender', true);
                                                formik.setFieldValue('gender', Number(e.target.value));
                                            }}>
                                                <option value={'default-value'} selected hidden>Select gender</option>
                                                <option value={0}>Male</option>
                                                <option value={1}>Female</option>
                                            </select>
                                            <span className={`${formik.errors.gender && formik.touched.gender ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.gender ? 'Gender required' : '\u00A0'}</span>
                                        </div>
                                        <div className=''>
                                            <label>Category</label>
                                            <select id="countries" value={categorySelect} name='categoryId' className="bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none" onChange={(e) => {
                                                setCategorySelect(e.target.value)
                                                formik.setFieldTouched('categoryId', true);
                                                formik.setFieldValue('categoryId', Number(e.target.value));
                                            }}>
                                                <option value={'default-value'} selected hidden>Select Category</option>
                                                {
                                                    category.map((item, index) =>
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                }
                                            </select>
                                            <span className={`${formik.errors.categoryId && formik.touched.categoryId ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.categoryId ? 'Category required' : '\u00A0'}</span>
                                        </div>
                                        <div className=''>
                                            <label>Interest</label>
                                            <Select
                                                // defaultValue={selectedOption}
                                                value={selectedOption}
                                                onChange={(selected) => {
                                                    setSelectedOption(selected);
                                                    const ids = selected.map(item => item.id);
                                                    formik.setFieldTouched('interestIds', true);
                                                    formik.setFieldValue('interestIds', ids);
                                                }}
                                                styles={customStyles}
                                                components={{
                                                    ValueContainer
                                                }}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                options={interests}
                                                hideSelectedOptions={false}
                                                formatGroupLabel={(data) => (
                                                    <div className="flex items-center justify-between">
                                                        <span>{data.label}</span>
                                                        <span className="bg-gray-200 rounded-full text-gray-800 text-xs font-normal leading-none min-w-0 px-2.5 text-center">
                                                            {data.options.length}
                                                        </span>
                                                    </div>
                                                )}
                                                // components={multiValueContainer}
                                                // menuPosition='fixed'1
                                                placeholder='Select interests'
                                                isMulti
                                            />
                                            <span className={`${formik.errors.interestIds && formik.touched.interestIds ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.interestIds ? 'Interests required' : '\u00A0'}</span>
                                        </div>
                                        <div className=''>
                                            <label>Country</label>
                                            <select id="countries" value={countrySelect} name='countryId' className="bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none" onChange={(e) => {
                                                setCountrySelect(e.target.value)
                                                formik.setFieldTouched('countryId', true);
                                                formik.setFieldValue('countryId', Number(e.target.value));
                                            }}>
                                                <option value={'default-value'} selected hidden>Select Country</option>
                                                {
                                                    country.map((item, index) =>
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                }
                                            </select>
                                            <span className={`${formik.errors.countryId && formik.touched.countryId ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.countryId ? 'Country required' : '\u00A0'}</span>
                                        </div>
                                        <div className=''>
                                            <label>City</label>
                                            <select id="countries" value={citySelect} name='cityId' className="bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none" onChange={(e) => {
                                                setCitySelect(e.target.value);
                                                formik.setFieldTouched('cityId', true);
                                                formik.setFieldValue('cityId', Number(e.target.value));
                                            }}>
                                                <option selected hidden>Select city or all</option>
                                                {
                                                    city.map((item, index) =>
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                }
                                            </select>
                                            <span className={`${formik.errors.cityId && formik.touched.cityId ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.cityId ? 'City required' : '\u00A0'}</span>
                                        </div>
                                    </div>
                                </div>

                                {/* contact */}
                                <div className='w-[409px] h-[580px] border border-black rounded-3xl p-6 pt-8 py-3'>
                                    <div className='text-3xl font-bold'>Contact details</div>

                                    <div className="max-w-full mx-auto">
                                        <div className='mt-4'>
                                            <label>Full name </label>
                                            <input type="text" name='fullName' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Type the list name here..." onChange={formik.handleChange} value={formik.values.fullName} />
                                            <span className={`${formik.errors.fullName && formik.touched.fullName ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.fullName && formik.touched.fullName ? formik.errors.fullName : '\u00A0'}</span>
                                        </div>
                                    </div>
                                    <div className="max-w-full mx-auto">
                                        <div className=''>
                                            <label>Phone Number</label>
                                            <input type="text" name='phone' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Type the list name here..." onChange={formik.handleChange} value={formik.values.phone} />
                                            <span className={`${formik.errors.phone && formik.touched.phone ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.phone && formik.touched.phone ? formik.errors.phone : '\u00A0'}</span>
                                        </div>
                                    </div>
                                    <div className="max-w-full mx-auto">
                                        <div className=''>
                                            <label>Email address </label>
                                            <input type="text" name='email' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Type the list name here..." onChange={formik.handleChange} value={formik.values.email} />
                                            <span className={`${formik.errors.email && formik.touched.email ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.email && formik.touched.email ? formik.errors.email : '\u00A0'}</span>
                                        </div>
                                    </div>
                                    <div className="max-w-full mx-auto">
                                        <div className='mt-3 flex gap-4 relative'>
                                            <img src={image ? image : upload} alt='upload-influener-img' className='w-[161px] h-[161px] object-contain border border-black rounded-3xl' />
                                            {/* <input type="file" className='absolute opacity-0 w-[40%] h-full top-0 left-0' onChange={handleImageChange} /> */}
                                            <div >
                                                <p className='text-black cursor-pointer relative'>
                                                    <div>Change</div>
                                                    <input type="file" className='absolute opacity-0 w-full h-full top-0 left-0' onChange={handleImageChange} />
                                                </p>
                                                <p className='text-[#FF0000] cursor-pointer' onClick={() => {
                                                    setImage(null);
                                                    setImageData(null);
                                                }}>Delete</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* Social media */}
                            <div className='my-8 w-[850px] h-[580px] border border-black rounded-3xl p-6 pt-8 py-3'>
                                <div className='text-3xl font-bold'>Social media accounts</div>
                                <div className="max-w-full mx-auto">
                                    <div className='mt-4'>
                                        <label>instagram</label>
                                        <input type="text" name='instagram' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Type the list name here..." onChange={formik.handleChange} value={formik.values.instagram} />
                                        <span className={`${formik.errors.instagram && formik.touched.instagram ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.instagram && formik.touched.instagram ? formik.errors.instagram : '\u00A0'}</span>
                                    </div>
                                </div>
                                <div className="max-w-full mx-auto">
                                    <div className=''>
                                        <label>TikTok</label>
                                        <input type="text" name='tiktok' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Paste URL here" onChange={formik.handleChange} value={formik.values.tiktok} />
                                        <span className={`${formik.errors.tiktok && formik.touched.tiktok ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.tiktok && formik.touched.tiktok ? formik.errors.tiktok : '\u00A0'}</span>
                                    </div>
                                </div>
                                <div className="max-w-full mx-auto">
                                    <div className=''>
                                        <label>Snapchat</label>
                                        <input type="text" name='snapchat' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Paste URL here" onChange={formik.handleChange} value={formik.values.snapchat} />
                                        <span className={`${formik.errors.snapchat && formik.touched.snapchat ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.snapchat && formik.touched.snapchat ? formik.errors.snapchat : '\u00A0'}</span>
                                    </div>
                                </div>
                                <div className="max-w-full mx-auto">
                                    <div className=''>
                                        <label>X</label>
                                        <input type="text" name='x' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Paste URL here" onChange={formik.handleChange} value={formik.values.x} />
                                        <span className={`${formik.errors.x && formik.touched.x ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.x && formik.touched.x ? formik.errors.x : '\u00A0'}</span>
                                    </div>
                                </div>
                                <div className="max-w-full mx-auto">
                                    <div className=''>
                                        <label>Youtube</label>
                                        <input type="text" name='youtube' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Paste URL here" onChange={formik.handleChange} value={formik.values.youtube} />
                                        <span className={`${formik.errors.youtube && formik.touched.youtube ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.youtube && formik.touched.youtube ? formik.errors.youtube : '\u00A0'}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <footer className={`fixed bottom-0 left-0 right-0 ${id ? 'h-14' : 'h-24'} bg-black`}>
                        {
                            id ?
                                null
                                :
                                <div className='font-bold flex items-center justify-end text-lg pr-4 py-2 bg-white cursor-pointer'>
                                    <button type="submit" className='pr-2' onClick={() => setSubmitStatus('saveAndAddAnother')}>Save and add another</button>
                                    <img src={arrowSvg} alt='save and add another one' className='w-4 h-4' />
                                </div>
                        }
                        <div className='font-bold flex items-center justify-end bg-black text-white text-lg pr-4 py-3 cursor-pointer'>
                            <button type="submit" className='pr-2' onClick={() => setSubmitStatus('save')}>Save</button>
                            <img src={whiteArrow} alt='save and add another one' className='w-4 h-4' />
                        </div>
                    </footer>
                </form>

            </main>
        </>
    )
}

export default InfluencerForm