import React, { useState } from 'react';
import { Pagination } from "antd";


const PaginationComponent = ({ count, page, setPage }) => {

    return (
        <div className='mt-4 mb-16'>
            <Pagination
                defaultCurrent={1}
                current={page}
                total={count}
                onChange={(value) => setPage(value)}
            />
        </div>
    );
}

export default PaginationComponent;