// import React, { useEffect, useState } from 'react';
// import Sidebar from '../components/Sidebar';
// import Breadcrumb from '../components/Breadcrumb';
// import addSvg from '../assets/whiteAdd.svg';
// import { useLocation } from 'react-router-dom';

// import api from '../helpers/api'

// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import Pagination from '../components/Pagination';
// import Header from '../components/Header';


// const CmsContent = () => {

//     const location = useLocation();

//     const [itemOffset, setItemOffset] = useState(0);
//     // states for lists

//     const [list, setList] = useState([]);
//     const [count, setCount] = useState(0);
//     const [offset, setOffset] = useState(0);
//     const [page, setPage] = useState(1);
//     const [search, setSearch] = useState('');

//     const handleLists = async (url) => {
//         try {
//             let response = await api.post(url, {
//                 search: null,
//                 startDate: null,
//                 endDate: null,
//                 offset: offset,
//                 limit: 10,
//                 userId: localStorage.getItem('userId')
//             });
//             setCount(response.data.count)
//             setList(response.data.result)
//             setPage(1)
//         } catch (err) {
//             console.log(err);
//         }
//     };

//     useEffect(() => {
//         let name = (location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6));

//         // based on condition we could submit here!

//         if (name == 'City') {
//             handleLists('/cities');
//         } else if (name == 'Interests') {

//         } else if (name == 'Categories') {

//         } else if (name == 'Country') {

//         }

//     }, []);

//     const handleChangePage = async (url) => {
//         const newOffset = (page - 1) * 10
//         try {
//             let response = await api.post(url, {
//                 search: null,
//                 startDate: null,
//                 endDate: null,
//                 offset: newOffset,
//                 limit: 10,
//                 userId: localStorage.getItem('userId')
//             });
//             setList(response.data.result)
//         }
//         catch (err) {
//             console.log(err);
//         }
//     };

//     useEffect(() => {
//         let name = (location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6));

//         if (name == 'City') {
//             handleChangePage('/cities')

//         } else if (name == 'Interests') {

//         } else if (name == 'Categories') {

//         } else if (name == 'Country') {

//         }

//     }, [page]);

//     const handleSearch = async (url) => {
//         try {
//             let response = await api.post(url, {
//                 search: search,
//                 startDate: null,
//                 endDate: null,
//                 offset: 0,
//                 limit: 10,
//                 userId: localStorage.getItem('userId')
//             });
//             setCount(response.data.count)
//             setList(response.data.result)
//         }
//         catch (err) {
//             console.log(err);
//         }
//     }

//     useEffect(() => {
//         let name = (location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6));

//         if (name == 'City') {
//             handleSearch('/cities')

//         } else if (name == 'Interests') {

//         } else if (name == 'Categories') {

//         } else if (name == 'Country') {

//         }

//     }, [search]);

//     const validationSchema = Yup.object({
//         title: Yup.string()
//             .matches(/^[A-Za-z]+$/, 'Title must contain only letters')
//             .required('name is required'),
//     });

//     const handleAddItemToList = async (url, name) => {

//         try {
//             let response = await api.post(url, { name: name, userId: localStorage.getItem('userId'), tz: "Asia/Calcutta" })
//             if (response.status === 200) {
//                 handleLists('/cities')
//             }
//         }
//         catch (err) {
//             console.log(err);
//         }
//     };


//     const formik = useFormik({
//         initialValues: {
//             name: '',
//         },
//         validationSchema: Yup.object({
//             name: Yup.string().required('Name is required'),
//         }),
//         onSubmit: values => {
//             // Handle form submission

//             let name = (location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6));

//             // based on condition we could submit here!

//             if (name == 'City') {
//                 handleAddItemToList('/city/add', values.name)
//             } else if (name == 'Interests') {

//             } else if (name == 'Categories') {

//             } else if (name == 'Country') {

//             }
//             formik.resetForm()
//         },
//     });


//     return (
//         <>
//             <Header setSearch={setSearch} />
//             <Sidebar />
//             <main className='mt-[80px] ml-[350px] pt-[30px] px-[80px] h-[100%] '>
//                 <Breadcrumb title={"Content management"} currentPage={'Home' + location.pathname} />

//                 <label>{(location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6))}</label>
//                 <form onSubmit={formik.handleSubmit}>
//                     <div className='w-[350px] flex items-center gap-4 mb-4'>
//                         <input
//                             type="text"
//                             name='name'
//                             className='bg-[#FAFAFA] border mt-1 h-[38px] border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]'
//                             placeholder={`Enter the ${(location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6))}`}
//                             onChange={formik.handleChange}
//                             value={formik.values.name}
//                         />
//                         <button
//                             type="submit"
//                             className="text-white w-[117px] h-[38px] bg-black border border-[#000000] hover:bg-black font-medium rounded-lg text-sm px-4 py-3 flex items-center gap-3"
//                         >
//                             Add <img src={addSvg} alt="add" />
//                         </button>
//                     </div>
//                 </form>
//                 <div className="relative overflow-x-auto">
//                     <table className="text-sm text-left rtl:text-right border-collapse border-spacing-y-4">
//                         <thead className="text-xs text-black bg-[#EAEAEA]">
//                             <tr className="mb-4">
//                                 <th scope="col" className="px-20 py-3 rounded-tl-lg rounded-bl-lg font-normal text-base	">
//                                     #
//                                 </th>
//                                 <th scope="col" className="px-20 py-3 font-normal text-base	">
//                                     Name
//                                 </th>
//                                 <th scope="col" className="px-20 py-3 rounded-tr-lg rounded-br-lg font-normal text-base	">
//                                     Action
//                                 </th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {
//                                 list.map((item, key) => {
//                                     return <React.Fragment key={key}>
//                                         <tr>
//                                             <td className="px-20 py-1"></td>
//                                             <td className="px-20 py-1"></td>
//                                             <td className="px-20 py-1"></td>
//                                         </tr>
//                                         <tr className="bg-[#FAFAFA] ">
//                                             <td className="px-20 py-3 rounded-bl-lg rounded-tl-lg text-base	">{((page - 1) * 10) + key + 1}</td>
//                                             <td className="px-20 py-3 text-base	">{item.name}</td>
//                                             <td className="px-20 py-3 rounded-br-lg rounded-tr-lg pt-4 text-base text-[#FF0000] cursor-pointer">
//                                                 Delete
//                                             </td>
//                                         </tr>
//                                     </React.Fragment>
//                                 })
//                             }
//                         </tbody>
//                     </table>
//                 </div>
//                 <Pagination page={page} setPage={setPage} count={count} />
//             </main>
//         </>
//     )
// }

// export default CmsContent;


import React, { useContext, useEffect, useRef, useState } from 'react';
import Sidebar from '../components/Sidebar';
import Breadcrumb from '../components/Breadcrumb';
import addSvg from '../assets/whiteAdd.svg';
import { useLocation, useNavigate } from 'react-router-dom';

import api from '../helpers/api'

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Pagination from '../components/Pagination';
import Header from '../components/Header';
import { CanvasContext } from '../context/MainContext';
import DeleteModel from '../components/DeleteModel';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { decryption, encryption } from '../cipher/cipher';

const CmsContent = () => {

    //id
    const currentId = useRef(null);

    // Locations
    const location = useLocation();

    let navigate = useNavigate();

    // States
    const [itemOffset, setItemOffset] = useState(0);
    const [list, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [sidebar, setSidebar] = useState({});
    const [deleteModel, setDeleteModel] = useState(false);

    // handle root list
    const handleLists = async (url) => {
        try {
            let encryptData = await encryption({
                search: null,
                startDate: null,
                endDate: null,
                offset: offset,
                limit: 10,
                userId: localStorage.getItem('userId')
            })
            let response = await api.post(url, { data: encryptData });

            if (response.status == 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                setCount(response.data.count)
                setList(response.data.result)
                setPage(1)
            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
        } catch (err) {
            console.log(err);
        }
    };

    // effects for initial rendering
    useEffect(() => {
        let name = (location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6));

        if (name == 'City') {
            handleLists('/cities');
        } else if (name == 'Interests') {
            handleLists('/interests');
        } else if (name == 'Categories') {
            handleLists('/categories');
        } else if (name == 'Country') {
            handleLists('/countries');
        }
    }, []);

    // effect for page changing
    useEffect(() => {
        let name = (location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6));

        const handleChangePage = async (url) => {
            const newOffset = (page - 1) * 10
            try {
                let encryptData = await encryption({
                    search: null,
                    startDate: null,
                    endDate: null,
                    offset: newOffset,
                    limit: 10,
                    userId: localStorage.getItem('userId')
                })
                let response = await api.post(url, { data: encryptData });


                if (response.status == 200) {
                    let decryptData = await decryption(response.data);
                    response.data = decryptData;
                    setList(response.data.result)
                } else if (response.status == 603) {
                    localStorage.clear();
                    navigate('/login')
                }
            }
            catch (err) {
                console.log(err);
            }
        };

        if (name == 'City') {
            handleChangePage('/cities');
        } else if (name == 'Interests') {
            handleChangePage('/interests');
        } else if (name == 'Categories') {
            handleChangePage('/categories');
        } else if (name == 'Country') {
            handleChangePage('/countries');
        }

    }, [page]);

    // effect for search
    useEffect(() => {
        let name = (location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6));

        const handleSearch = async (url) => {
            try {
                let encryptData = await encryption({
                    search: search,
                    startDate: null,
                    endDate: null,
                    offset: 0,
                    limit: 10,
                    userId: localStorage.getItem('userId')
                })
                let response = await api.post(url, { data: encryptData });


                if (response.status == 200) {
                    let decryptData = await decryption(response.data);
                    response.data = decryptData;
                    setCount(response.data.count)
                    setList(response.data.result)
                    setPage(1)
                }
                else if (response.status == 603) {
                    localStorage.clear();
                    navigate('/login')
                }
            }
            catch (err) {
                console.log(err);
            }
        }

        if (name == 'City') {
            handleSearch('/cities');
        } else if (name == 'Interests') {
            handleSearch('/interests');
        } else if (name == 'Categories') {
            handleSearch('/categories');
        } else if (name == 'Country') {
            handleSearch('/countries');
        }

    }, [search]);

    // Add item to list
    const handleAddItemToList = async (url, name) => {
        try {
            let encryptData = await encryption({ name: name, userId: localStorage.getItem('userId'), tz: "Asia/Calcutta" })
            let response = await api.post(url, { data: encryptData });

            if (response.status === 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                let name = (location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6));
                if (name == 'City') {
                    handleLists('/cities');
                } else if (name == 'Interests') {
                    handleLists('/interests');
                } else if (name == 'Categories') {
                    handleLists('/categories');
                } else if (name == 'Country') {
                    handleLists('/countries');
                }
            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    // form
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
        }),
        onSubmit: values => {
            let name = (location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6));

            if (name == 'City') {
                handleAddItemToList('/city/add', values.name)
            } else if (name == 'Interests') {
                handleAddItemToList('/interest/add', values.name)
            } else if (name == 'Categories') {
                handleAddItemToList('/category/add', values.name)
            } else if (name == 'Country') {
                handleAddItemToList('/country/add', values.name)
            };

            formik.resetForm()
        },
    });

    const hanleDeleteinList = async () => {
        setDeleteModel(!deleteModel);

        let id = currentId.current;
        let name = (location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6));
        let url = '';

        if (name == 'City') {
            url = '/city/delete'
        } else if (name == 'Interests') {
            url = '/interest/delete'
        } else if (name == 'Categories') {
            url = '/category/delete'
        } else if (name == 'Country') {
            url = '/country/delete'
        };

        try {
            let encryptData = await encryption({ id: id, userId: localStorage.getItem('userId') })
            let response = await api.post(url, { data: encryptData });


            if (response.status === 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                let name = (location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6));
                if (name == 'City') {
                    await handleLists('/cities');
                    toast.success("Oops! Successfully deleted", {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    })
                } else if (name == 'Interests') {
                    await handleLists('/interests');
                    toast.success("Oops! Successfully deleted", {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    })
                } else if (name == 'Categories') {
                    await handleLists('/categories');
                    toast.success("Oops! Successfully deleted", {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    })
                } else if (name == 'Country') {
                    await handleLists('/countries');
                    toast.success("Oops! Successfully deleted", {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    })
                }
            } else if (response.status == 605) {
                toast.error("Oops! Item already used.", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
            } else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <Header setSearch={setSearch} />
            {/* <Sidebar setSidebar={setSidebar} /> */}
            <main className='mt-[145px]  xl:mt-[80px] pt-[30px] px-[80px] h-[calc(100vh-145px)] xl:h-[calc(100vh-80px)'>
                <Breadcrumb title={"Content management"} currentPage={[{ label: "CMS", class: null, link: '/CMS' }, { label: location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6) === 'City' ? 'City' : location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6) === 'Interests' ? 'Interest' : location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6) === 'Categories' ? 'Category' : location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6) === 'Country' ? 'Country' : null, class: 'active' }]} />
                <label>{(location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6))}</label>
                <form onSubmit={formik.handleSubmit}>
                    <div className='flex items-center gap-4 mb-4'>
                        <input
                            type="text"
                            name='name'
                            className='bg-[#FAFAFA] border mt-1 h-[38px] border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]'
                            placeholder={`Enter the ${(location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6))}`}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                        <button
                            type="submit"
                            className="text-white w-[117px] h-[38px] bg-black border border-[#000000] hover:bg-black font-medium rounded-lg text-sm px-4 py-3 flex items-center gap-3"
                        >
                            Add <img src={addSvg} alt="add" />
                        </button>
                    </div>
                </form>
                <div className="relative overflow-x-auto">
                    <table className="table-fixed w-full text-sm text-left rtl:text-right border-collapse">
                        <thead className="text-xs text-black bg-[#EAEAEA]">
                            <tr className="mb-4">
                                <th scope="col" className="p-3 rounded-tl-lg rounded-bl-lg font-normal text-base	">
                                    #
                                </th>
                                <th scope="col" className="p-3 font-normal text-base	">
                                    Name
                                </th>
                                <th scope="col" className="p-3 rounded-tr-lg rounded-br-lg font-normal text-base	">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((item, key) => {
                                    return <React.Fragment key={key}>
                                        <tr>
                                            <td className=""> </td>
                                            <td className=""> </td>
                                            <td className=""> </td>
                                        </tr>
                                        <tr className="bg-[#FAFAFA] ">
                                            <td className=" p-3 rounded-bl-lg rounded-tl-lg text-base	">{((page - 1) * 10) + key + 1}</td>
                                            <td className=" p-3 text-base	">{item.name}</td>
                                            <td className=" p-3 rounded-br-lg rounded-tr-lg pt-4 text-base text-[#FF0000] cursor-pointer" onClick={() => {
                                                setDeleteModel(!deleteModel);
                                                currentId.current = item.id
                                            }}>
                                                Delete
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination page={page} setPage={setPage} count={count} />
            </main >
            <DeleteModel deleteModel={deleteModel} setDeleteModel={setDeleteModel} handleDelete={hanleDeleteinList} />
            <ToastContainer />
        </>
    )
}

export default CmsContent