// import React from 'react'

// const Breadcrumb = ({ title, currentPage }) => {
//     return (
//         // <div className='mb-8'>
//         //     <h3 className="text-3xl font-bold ">{title}</h3>
//         //     <p>{currentPage}</p>
//         // </div>
//         <div className="flex items-center gap-2">
//             <a href="/" className="-mt-0.5" matTooltip="Dashboard">
//                 <custom-svg className="custom-svg" src="assets/img/home.svg" />
//             </a>
//         </div>

//     )
// }

// export default Breadcrumb;


import React from 'react';
import { Link } from 'react-router-dom';
// import CustomSvg from './CustomSvg'; 

const Breadcrumb = ({ title, currentPage }) => {
    let crumbs = currentPage;
    return (
        <div className="mb-8">
            <h3 className="text-3xl font-bold ">{title}</h3>
            <div className='flex items-center gap-2 '>
                <div className="">
                    <Link to="/" className="-mt-0.5" title="Dashboard">
                        Home
                    </Link>
                </div>
                {crumbs.map((crumb, index) => (
                    <React.Fragment key={index}>
                        <span className="text-xl">/</span>
                        {crumb.class == null ?
                            <Link
                                to={crumb.link}
                                className="transition-all duration-300 hover:text-[#F15625] text-xs sm:text-sm"
                            >
                                {crumb.label}
                            </Link>
                            :
                            <p className="font-semibold text-[#F15625] text-xs sm:text-sm mt-[1px]">
                                {crumb.label}
                            </p>
                        }
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default Breadcrumb;