import CryptoJS from 'crypto-js';
const SECRET_KEY = 'be4671fca118a2eac9db6b81e89d078bd1a259c6f8d20ed291946235a12e46ad0d0046059e434d2ab42dbb032aec02f153bfa9e65644ad75691a2653a2ad7602';


// Encryption 
const encryption = async (value) => {
    return CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
};

// Decryption
const decryption = async (value) => {
    return JSON.parse(CryptoJS.AES.decrypt(value, SECRET_KEY).toString(CryptoJS.enc.Utf8) || '');
};

export { encryption, decryption }