import React from 'react';

import instagram from '../assets/socialMediaIcons/instagram.svg';
import tikTok from '../assets/socialMediaIcons/tikTok.svg';
import snapChat from '../assets/socialMediaIcons/snapChat.svg';
import x from '../assets/socialMediaIcons/x.svg';
import youtube from '../assets/socialMediaIcons/youtube.svg';

import add from '../assets/socialMedia/add.svg';
import downArrow from '../assets/downArrow.svg';
import interest from '../assets/interest.svg';

import upload from '../assets/upload.svg';
import user from '../assets/user.svg';


const ListInfluencerCard = ({ info, handleDeleteList }) => {

    const privileges = JSON.parse(localStorage.getItem('privileges')) || [];

    return (
        <div className='w-[400px] h-[220px] rounded-2xl border border-black p-4 flex justify-between items-start gap-4'>
            <div>
                <img src={info?.imageUrl ? global.config.API_URL + info?.imageUrl : upload} alt='influencer-image' className={`w-[161px] h-[161px]  ${info?.imageUrl ? 'rounded-2xl object-cover' : ''}`} />
            </div>
            <div>
                <div className='font-bold'>{info?.fullName}</div>
                <div>{info?.countryName}</div>
                <div className='mb-2'>{info?.cityName}</div>
                <div className='flex gap-2'><img src={user} alt='user' /><div>{info?.gender == 0 ? "Male" : "Female"}</div></div>
                {/* Food & Beverage, fashion, beauty */}
                <div className='flex gap-2 items-start w-[200px]'><img src={interest} alt='menu' className='pt-2' /><div>{info?.interestName && info?.interestName.length > 15 ? info?.interestName.slice(0, 15) + '...' : info?.interestName}</div></div>

                <div className='flex items-center justify-between mt-4'>
                    <div className='flex gap-3 items-center'>
                        <img src={instagram} className='w-[13px] h-[13px] cursor-pointer' onClick={() => {
                            if (info?.instagram) {
                                window.open(info?.instagram, '_blank')
                            }
                        }} />
                        <img src={tikTok} className='w-[13px] h-[13px] cursor-pointer' onClick={() => {
                            if (info?.tiktok) {
                                window.open(info?.tiktok, '_blank')
                            }
                        }} />
                        <img src={snapChat} className='w-[13px] h-[13px] cursor-pointer' onClick={() => {
                            if (info?.snapchat) {
                                window.open(info?.snapchat, '_blank')
                            }
                        }} />
                        <img src={x} className='w-[13px] h-[13px] cursor-pointer' onClick={() => {
                            if (info?.x) {
                                window.open(info?.x, '_blank')
                            }
                        }} />
                        <img src={youtube} className='w-[13px] h-[13px] cursor-pointer' onClick={() => {
                            if (info?.youtube) {
                                window.open(info?.youtube, '_blank')
                            }
                        }} />
                    </div>
                    {
                        privileges.includes('influencerDelete') &&
                        <div className='text-[#F05423] cursor-pointer' onClick={() => handleDeleteList(info?.id)}>Remove</div>
                    }
                </div>


            </div>
            {/* <div className='font-bold text-sm'>List Name</div>
            <div className='font-bold text-xl'>230 influencers</div>

            <div className='flex gap-3 mt-2 mb-8'>
                <img src={instagram} className='w-[12.72px] h-[12.72px]' />
                <img src={tikTok} className='w-[12.72px] h-[12.72px]' />
                <img src={snapChat} className='w-[12.72px] h-[12.72px]' />
                <img src={x} className='w-[12.72px] h-[12.72px]' />
                <img src={youtube} className='w-[12.72px] h-[12.72px]' />
            </div>

            <div className='flex justify-between items-center'>
                <button type="button" className="text-black w-[180px] h-[38px] bg-[#FAFAFA] border border-[#000000] font-medium rounded-lg text-sm flex items-center justify-between p-4">
                    <p>Add influencer</p>
                    <img src={add} alt='view-comments' />
                </button>
                <button type="button" className="text-black h-[38px] rounded-lg text-sm flex items-center justify-between p-4 gap-2">
                    <img src={downArrow} alt='change-status' />
                    <p>Status : submitted</p>
                </button>
            </div>
            <img src={menu} alt='menu' className='absolute top-[20px] right-[10px] cursor-pointer' /> */}
        </div>
    )
}

export default ListInfluencerCard;
