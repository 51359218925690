import React, { useEffect, useRef, useState } from 'react'
import Breadcrumb from '../components/Breadcrumb';
import whiteArrow from '../assets/whiteArrow.svg';

import { useFormik } from 'formik';
import { number, object, string, ref } from 'yup';
import api from '../helpers/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import DeleteModel from '../components/DeleteModel';
import { decryption, encryption } from '../cipher/cipher';


const EditUser = () => {

    let { id } = useParams();
    let location = useLocation();

    let currentId = useRef(null);

    const passwordRef = useRef();
    let navigate = useNavigate();
    let [search, setSearch] = useState('');
    let [roleList, setRoleList] = useState([]);
    const [roleSelect, setRoleSelect] = useState('default-value');

    const [deleteModel, setDeleteModel] = useState(false);


    const handleRoleList = async () => {
        try {
            let encryptData = await encryption({ userId: localStorage.getItem('userId') })
            let response = await api.post('/roles/list', { data: encryptData });



            if (response.status == 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                setRoleList(response.data)
            } else if (response.status == 603) {
                localStorage.clear();
                navigate('/login');
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleGetDetails = async () => {
        try {
            let encryptData = await encryption({ userId: localStorage.getItem('userId'), id: id })
            let response = await api.post('/user', { data: encryptData });



            if (response.status == 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                formik.setFieldValue('userName', response.data?.userName);

                setRoleSelect(response.data?.roleId)
                formik.setFieldValue('roleId', response.data?.roleId);
                formik.setErrors('')
                passwordRef.current = response.data?.password;

            } else if (response.status == 603) {
                localStorage.clear();
                navigate('/login');
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        handleGetDetails()
        handleRoleList();
    }, []);

    useEffect(() => {
        handleGetDetails()
        handleRoleList();
    }, [location.pathname]);


    const userSchema = object().shape({
        roleId: number()
            .required('required'),
        userName: string().email().required('Username is required'),
        password: string()
            .min(7, 'Password must be at least 8 characters long')
            .max(20, 'Password cannot be more than 20 characters long')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(/[\W_]/, 'Password must contain at least one special character'),

        confirmPassword: string()
            .oneOf([ref('password'), null], 'Passwords must match')
            .when("password", (password, schema) => {
                let value = password[0];
                if (value)
                    return schema.required("Confirm password is required")
                return schema
            })
    });


    const handleUserFormEdit = async (values) => {
        try {
            let password = values?.password;
            if (!password) {
                values.password = passwordRef.current;
            }

            let request = {
                userName: values.userName,
                password: values.password,
                status: 0,
                roleId: values.roleId,
                userId: localStorage.getItem('userId'),
                tz: "Asia/Calcutta",
                id: id
            }
            let encryptData = await encryption(request)
            let response = await api.post('/user/update', { data: encryptData });

            if (response.status == 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                navigate('/users');
            } else if (response.status == 603) {
                localStorage.clear();
                navigate('/login');
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const formik = useFormik({
        initialValues: {
            roleId: null,
            userName: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: userSchema,
        onSubmit: values => {
            // alert(JSON.stringify(values));

            handleUserFormEdit(values);
            setRoleSelect('default-value')
            formik.resetForm()
        },
    });

    // handle selector 

    const handleSelector = (e) => {
        setRoleSelect(e.target.value)
        formik.setFieldValue('roleId', Number(e.target.value));
    }

    const handleDeleteUser = async () => {
        try {

            setDeleteModel(!deleteModel)
            let encryptData = await encryption({ id: id, userId: localStorage.getItem('userId') })
            let response = await api.post('/user/delete', { data: encryptData });


            if (response.status == 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                toast.success("Successfully deleted. !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
                setTimeout(() => {
                    navigate('/users')
                }, 100)
            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/login');
            }
            else if (response.status == 605) {
                toast.error("You can't delete this user. !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <Header setSearch={setSearch} />
            <main className='mt-[145px] xl:mt-[110px] 2xl:mt-[80px] pt-[30px] px-[80px] h-[calc(100vh-145px)] xl:h-[calc(100vh-110px)] 2xl:h-[calc(100vh-80px)] w-full'>
                <div className='flex justify-center items-center'>
                    <div className='mb-16 relative'>
                        <Breadcrumb title={"Users"} currentPage={[{ label: 'Edit user', class: 'active' }]} />

                        <form className='flex justify-between w-[700px] gap-16' onSubmit={formik.handleSubmit}>
                            <div className='w-full'>
                                <div className='mt-4'>
                                    <label>Role <span className='text-[#FF0000]'>*</span></label>
                                    <select id="roleId" name='roleId' value={roleSelect} className="bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none" onChange={handleSelector}>
                                        <option value={'default-value'} selected hidden>Select role</option>
                                        {
                                            roleList.map((item, index) =>
                                                <option key={index} value={item.id}>{item.name}</option>
                                            )
                                        }
                                    </select>
                                    <span className={`${formik.errors.roleId ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.roleId ? formik.errors.roleId : '\u00A0'}</span>
                                </div>
                                <div className=''>
                                    <label>username <span className='text-[#FF0000]'>*</span></label>
                                    <input type="text" name='userName' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Enter password" onChange={formik.handleChange} value={formik.values.userName} />
                                    <span className={`${formik.errors.userName && formik.touched.userName ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.userName && formik.touched.userName ? formik.errors.userName : '\u00A0'}</span>
                                </div>
                            </div>

                            <div className='w-full'>
                                <div className='mt-4'>
                                    <label>New Password</label>
                                    <input type="text" name='password' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Enter password" onChange={formik.handleChange} value={formik.values.password} />
                                    <span className={`${formik.errors.password && formik.touched.password ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.password && formik.touched.password ? formik.errors.password : '\u00A0'}</span>
                                </div>
                                <div className=''>
                                    <label>confirm password</label>
                                    <input type="text" name='confirmPassword' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Enter password" onChange={formik.handleChange} value={formik.values.confirmPassword} />
                                    <span className={`${formik.errors.confirmPassword && formik.touched.confirmPassword ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.confirmPassword && formik.touched.confirmPassword ? formik.errors.confirmPassword : '\u00A0'}</span>
                                </div>
                            </div>


                            <footer className='fixed bottom-0 left-0 right-0 h-10 bg-black'>
                                <div className='font-bold flex items-center justify-end bg-black text-white text-lg pr-4 pt-1 cursor-pointer'>
                                    {/* <div className='pr-2'>Save</div> */}
                                    <button className='pr-2' type='submit'>Save</button>
                                    <img src={whiteArrow} alt='save and add another one' className='w-4 h-4' />
                                </div>
                            </footer>
                        </form>
                        <div className='absolute top-0 right-0 text-[#FF0000] cursor-pointer' onClick={() => {
                            setDeleteModel(!deleteModel);
                            currentId.current = id
                        }
                        }>Delete</div>
                    </div>
                </div>
            </main>
            <ToastContainer />
            {/* <DeleteModel deleteModel={deleteModel} setDeleteModel={setDeleteModel} handleDelete={hanleDeleteinList} /> */}
            <DeleteModel deleteModel={deleteModel} setDeleteModel={setDeleteModel} handleDelete={handleDeleteUser} />

        </>

    )
}

export default EditUser;