import React, { useEffect, useRef, useState } from 'react'
import Breadcrumb from '../components/Breadcrumb';

import interest from '../assets/interest.svg';

import upload from '../assets/upload.svg';
import star from '../assets/star.svg';
import user from '../assets/user.svg';
import likeInActive from '../assets/likeInActive.svg'
import likeActive from '../assets/likeActive.svg'
import Header from '../components/Header';
import api from '../helpers/api';
import { useNavigate, useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import { object, string, number, date, } from 'yup';

import InfiniteScroll from "react-infinite-scroll-component";
import { decryption, encryption } from '../cipher/cipher';


const ViewComments = () => {

    const privileges = JSON.parse(localStorage.getItem('privileges')) || [];

    let navigate = useNavigate();

    const { id } = useParams();
    const [info, setInfo] = useState({});
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState('');

    const [hasMore, setHasMore] = useState(true);

    const [comments, setComments] = useState([]);


    const countRef = useRef(0);
    const page = useRef(1);

    const stopRef = useRef(false);

    const handleUserInfo = async () => {
        try {
            let encryptData = await encryption({ userId: localStorage.getItem('userId'), id: id })
            let response = await api.post('/influencer', { data: encryptData });

            if (response.status == 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                setInfo(response.data)
            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const handleInitialComment = async () => {
        try {
            let encryptData = await encryption({
                search: search ? search : null,
                startDate: null,
                endDate: null,
                offset: 0,
                limit: 10,
                influencerId: id,
                userId: localStorage.getItem('userId')
            })
            let response = await api.post('/influencer/comments', { data: encryptData })


            if (response.status == 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                setComments(response.data.result)
            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        page.current = 1;
        handleInitialComment();
    }, [search])

    useEffect(() => {
        handleUserInfo();
        handleInitialComment();
    }, []);

    // handle likes 

    const handleLikeCount = (value) => {
        if (countRef.current === 1) {
            countRef.current = 0;
            setCount(0)
        } else {
            countRef.current = value
            setCount(value);
        }
    };

    const validationSchema = object().shape({
        comments: string().max(500, 'Comments cannot exceed 500 characters'),
    });

    const handleSubmitComments = async (values) => {
        try {
            let encryptData = await encryption({ comments: values.comments, rating: count, influencerId: id, tz: "Asia/Calcutta", userId: localStorage.getItem('userId') })
            let response = await api.post('/influencer/comments/add', { data: encryptData });

            setCount(0)
            if (response.status == 200) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                page.current = 1;
                handleInitialComment();
            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const formik = useFormik({
        initialValues: {
            comments: ''
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmitComments(values)
            formik.resetForm();
        },
    });


    const fetchMoreData = async () => {
        try {
            page.current = page.current + 1;
            const newOffset = (page.current - 1) * 10;

            let encryptData = await encryption({
                search: search ? search : null,
                startDate: null,
                endDate: null,
                offset: newOffset,
                influencerId: id,
                limit: 10,
                userId: localStorage.getItem('userId')
            })
            let response = await api.post('/influencer/comments', { data: encryptData });


            if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }

            let decryptData = await decryption(response.data);
            response.data = decryptData;

            setTimeout(() => {
                setComments(preVal => {
                    return preVal.concat(response.data.result)
                })
            }, 500);
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <Header setSearch={setSearch} />
            <main className='mt-[145px] xl:mt-[110px] 2xl:mt-[80px]  pt-[30px] px-[80px] h-[calc(100vh-145px)] xl:h-[calc(100vh-110px)] 2xl:h-[calc(100vh-80px)] w-full'>
                <div className='flex justify-center items-center'>
                    <div className='mb-16'>
                        <Breadcrumb title={info?.fullName} currentPage={[{ label: "Influencer", class: "active", }]} />
                        <div className='flex justify-between items-start gap-4 mb-8 w-[455px]'>
                            <div>
                                <img src={info?.imageUrl ? global.config.API_URL + info?.imageUrl : upload} alt='influencer-image' className={`w-[161px] h-[161px] object-cover ${info?.imageUrl ? 'rounded-2xl' : ''}`} />
                            </div>
                            <div>
                                <div>Full name: {info?.fullName}</div>
                                <div>Phone number: +91 {info?.phone}</div>
                                <div>email address: {info?.email}</div>
                                <div className='flex gap-2 mt-3'><img src={user} alt='user' /><div>{info?.gender == 0 ? 'Male' : 'Female'}</div></div>
                                <div className='flex gap-2 mt-3'><img src={star} alt='user' /><div>Ratings: {info?.average}</div></div>
                                <div className='flex gap-2 items-start'><img src={interest} alt='menu' className='pt-2' /><div>{(info?.interestName) && (info?.interestName).length > 15 ? info?.interestName.slice(0, 15) + '...' : info?.interestName}</div></div>
                            </div>
                        </div>
                        <div>
                            {
                                privileges.includes('ratings') &&
                                <div className='flex items-center justify-between'>
                                    <div className='font-bold text-2xl mb-3'>Post a comment</div>
                                    <div className='font-bold text-2xl flex gap-2'>
                                        <img src={count >= 1 ? likeActive : likeInActive} alt='ratings' className='cursor-pointer' onClick={() => handleLikeCount(1)} />
                                        <img src={count >= 2 ? likeActive : likeInActive} alt='ratings' className='cursor-pointer' onClick={() => handleLikeCount(2)} />
                                        <img src={count >= 3 ? likeActive : likeInActive} alt='ratings' className='cursor-pointer' onClick={() => handleLikeCount(3)} />
                                        <img src={count >= 4 ? likeActive : likeInActive} alt='ratings' className='cursor-pointer' onClick={() => handleLikeCount(4)} />
                                        <img src={count >= 5 ? likeActive : likeInActive} alt='ratings' className='cursor-pointer' onClick={() => handleLikeCount(5)} />
                                    </div>
                                </div>
                            }
                            {
                                privileges.includes('commentsAdd') &&
                                <form onSubmit={formik.handleSubmit}>
                                    <textarea id="message" rows="4" name='comments' className="block w-[973px] text-sm text-gray-900 p-4 outline-none bg-[#FAFAFA] rounded-3xl border border-black focus:ring-blue-500 focus:border-blue-500 " placeholder="Start typing here..." onChange={formik.handleChange} value={formik.values.comments} />
                                    <span className={`${formik.errors.comments && formik.touched.comments ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.comments && formik.touched.comments ? formik.errors.comments : '\u00A0'}</span>
                                    <div className='mt-3 flex justify-end'>
                                        <button type="submit" className="text-white w-[140px] h-[44px] bg-black border border-[#000000] hover:bg-black font-medium rounded-lg text-sm px-4 py-3">Send</button>
                                    </div>
                                </form>
                            }

                        </div>

                        <div className='mb-4'>
                            <div className='font-bold text-xl'>All Comments</div>
                        </div>

                        {/* comment section */}
                        <div>

                            <InfiniteScroll
                                dataLength={comments.length}
                                next={fetchMoreData}
                                hasMore={hasMore}
                            >
                                {
                                    comments.map((comment, index) => {
                                        return (
                                            <div className='w-[973px] h-[191px] border border-[#EAEAEA] p-4 rounded-2xl mb-4' key={index}>
                                                <div className='flex items-center justify-between'>
                                                    <div className='mb-3'>Posted by : sales user name</div>
                                                    <div className='font-bold text-2xl flex gap-2'>
                                                        <img src={comment.rating >= 1 ? likeActive : likeInActive} alt='ratings' className='cursor-default' />
                                                        <img src={comment.rating >= 2 ? likeActive : likeInActive} alt='ratings' className='cursor-default' />
                                                        <img src={comment.rating >= 3 ? likeActive : likeInActive} alt='ratings' className='cursor-default' />
                                                        <img src={comment.rating >= 4 ? likeActive : likeInActive} alt='ratings' className='cursor-default' />
                                                        <img src={comment.rating >= 5 ? likeActive : likeInActive} alt='ratings' className='cursor-default' />
                                                    </div>
                                                </div>
                                                <div className='h-[109px]'>{comment.comments}</div>
                                                <div className='flex justify-end'>{comment.createdAt}</div>
                                            </div>
                                        )
                                    })
                                }
                            </InfiniteScroll>
                        </div>

                    </div>
                </div>
            </main>
        </>
    )
}

export default ViewComments;


/* 
                        <InfiniteScroll
                            dataLength={dataSource.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            // loader={<p>Loading...</p>}
                        >
*/

/*     const fetchMoreData = async () => {
        page.current = page.current + 1;
        const newOffset = (page.current - 1) * 10;

        let response = await api.post('/influencers', {
            search: null,
            startDate: null,
            endDate: null,
            offset: newOffset,
            limit: 10,
            sort: null,
            countryId: null,
            cityId: null,
            categoryId: null,
            interestId: null,
            gender: null,
            instagram: null,
            x: null,
            youtube: null,
            tiktok: null,
            snapchat: null,
            ratings: null,
            userId: localStorage.getItem('userId')
        })

        if (response.data.result.length < 10) {

        }

        setTimeout(() => {
            setDataSource(dataSource.concat(response.data.result))
        }, 500);
    }; */