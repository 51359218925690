import React, { useEffect, useState } from 'react';

import instagram from '../assets/socialMediaIcons/instagram.svg';
import tikTok from '../assets/socialMediaIcons/tikTok.svg';
import snapChat from '../assets/socialMediaIcons/snapChat.svg';
import x from '../assets/socialMediaIcons/x.svg';
import youtube from '../assets/socialMediaIcons/youtube.svg';

import add from '../assets/socialMedia/add.svg';
import downArrow from '../assets/downArrow.svg';
import menu from '../assets/menu.svg';
import { Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import api from '../helpers/api';
import { decryption, encryption } from '../cipher/cipher';



const ListCard = ({ info, handleRemoveList, handleAddDuplicateList }) => {

    const privileges = JSON.parse(localStorage.getItem('privileges')) || [];

    let navigate = useNavigate();

    let items = [
        {
            key: '1',
            label: "View",
        },
        {
            key: '2',
            label: "Duplicate",
        },
        {
            key: '3',
            label: "Delete",
        },
    ];

    const onClick = ({ key }) => {
        if (key === '1') {
            navigate('/influencer/by/list/' + info.id)
        }
        else if (key === '2') {
            handleAddDuplicateList(info.id);
        }
        if (key === '3') {
            handleRemoveList(info.id)
        }
    };

    const handleSubmitListStatus = async (event) => {
        try {
            let request = {
                id: info?.id,
                status: Number(event.target.value),
                tz: "Asia/Calcutta",
                userId: localStorage.getItem('userId')
            };
            let encryptData = await encryption(request);
            let response = await api.post('/list/status/update', { data: encryptData });

            if (response.status === 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                // we don't change anything not needed
            } else if (response.status === 603) {
                localStorage.clear();
                navigate('/login');
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className='w-[384px] h-[181px] rounded-2xl border border-black p-4 relative'>
                <div className='font-bold text-sm'>{info?.name}</div>
                <div className='font-bold text-xl'>{info?.influencerCount} influencers</div>

                <div className='flex gap-3 mt-2 mb-8'>
                    <img src={instagram} className='w-[12.72px] h-[12.72px]' />
                    <img src={tikTok} className='w-[12.72px] h-[12.72px]' />
                    <img src={snapChat} className='w-[12.72px] h-[12.72px]' />
                    <img src={x} className='w-[12.72px] h-[12.72px]' />
                    <img src={youtube} className='w-[12.72px] h-[12.72px]' />
                </div>

                <div className='flex justify-between items-center relative'>
                    <button type="button" className="text-black w-[180px] h-[38px] bg-[#FAFAFA] border border-[#000000] font-medium rounded-lg text-sm flex items-center justify-between p-4" onClick={() => window.location.href = '/list/by/influencer/' + info?.id}>
                        <p>Add influencer</p>
                        <img src={add} alt='view-comments' />
                    </button>
                    {
                        privileges.includes('listStatus') &&
                        <button type="button" className="text-black h-[38px] rounded-lg text-sm flex items-center justify-between p-4 gap-2">
                            <select defaultValue={info?.status ? info?.status : 0} onChange={(event) => handleSubmitListStatus(event)}>
                                <option value={0}>Submitted</option>
                                <option value={1}>Confirmed</option>
                                <option value={2}>Executed</option>
                                <option value={3}>Cancelled</option>
                            </select>
                        </button>
                    }
                </div>

                <Dropdown menu={{ items, onClick }} trigger={['click']} placement="bottom">
                    <img src={menu} alt='menu' className='absolute top-[20px] right-[10px] cursor-pointer' onClick={(e) => e.preventDefault()} />
                </Dropdown>
            </div>
        </>
    )
}

export default ListCard;