import React, { useEffect, useState, useRef, useContext } from 'react'
import Breadcrumb from '../components/Breadcrumb'
import ListCard from '../components/ListCard'
import ListInfluencerCard from '../components/ListInfluencerCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import api from '../helpers/api';

import whiteArrow from '../assets/whiteArrow.svg';
import { CanvasContext } from '../context/MainContext';
import { decryption, encryption } from '../cipher/cipher';



const AddInfluencers = () => {

    let navigate = useNavigate();

    let { ID } = useParams()
    let [search, setSearch] = useState();
    const [hasMore, setHasMore] = useState(true);
    let location = useLocation();

    let { listId, setRightBar, rightBar, listArr, setListArr } = useContext(CanvasContext);

    let page = useRef();

    const [count, setCount] = useState(0);
    const [title, setTitle] = useState('');

    let [lists, setLists] = useState([]);

    // const handleInitialList = async () => {
    //     try {
    //         let encryptData = await encryption({
    //             search: null,
    //             startDate: null,
    //             endDate: null,
    //             offset: 0,
    //             limit: 10,
    //             sort: 0,
    //             countryId: null,
    //             listId: ID ? ID : null,
    //             cityId: null,
    //             categoryId: null,
    //             interestId: null,
    //             gender: null,
    //             instagram: null,
    //             tiktok: null,
    //             snapChat: null,
    //             x: null,
    //             youtube: null,
    //             ratings: null,
    //             userId: localStorage.getItem('userId')
    //         })
    //         let response = await api.post('/influencers', { data: encryptData });

    //         if (response.status == 200) {
    //             let decryptData = await decryption(response.data);
    //             response.data = decryptData;
    //             setLists(response.data.result);
    //             setCount(response.data.count);
    //         }
    //         else if (response.status == 603) {
    //             localStorage.clear();
    //             navigate('/login')
    //         }
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // };

    const handleTitle = async () => {
        try {
            let encryptData = await encryption({ listId: ID, userId: localStorage.getItem('userId') });
            let response = await api.post('/customer/list', { data: encryptData });

            if (response.status == 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                setTitle('' + response.data.companyName + ', ' + response.data.listName)
            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (listArr.length === 0) {
            navigate('/list/by/influencer/' + ID)
        }
        handleTitle();
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // page.current = 1;
        // handleInitialList();
    }, [search])

    // const fetchMoreData = async () => {
    //     page.current = page.current + 1;
    //     const newOffset = (page.current - 1) * 10;

    //     let encryptData = await encryption({
    //         search: null,
    //         startDate: null,
    //         endDate: null,
    //         offset: newOffset,
    //         limit: 10,
    //         sort: 0,
    //         countryId: null,
    //         listId: ID ? ID : null,
    //         cityId: null,
    //         categoryId: null,
    //         interestId: null,
    //         gender: null,
    //         instagram: null,
    //         tiktok: null,
    //         snapChat: null,
    //         x: null,
    //         youtube: null,
    //         ratings: null,
    //         userId: localStorage.getItem('userId')
    //     })

    //     let response = await api.post('/influencers', { data: encryptData });

    //     if (response.status == 603) {
    //         localStorage.clear();
    //         navigate('/login')
    //     }

    //     let decryptData = await decryption(response.data);
    //     response.data = decryptData;

    //     /* Use the below logic  */
    //     if (response.data.result.length < 10) {

    //     }

    //     setTimeout(() => {
    //         setLists(lists.concat(response.data.result))
    //     }, 500);
    // }

    const handleDeleteList = async (influencerId) => {
        try {
            setListArr(prevItems => prevItems.filter(item => item.id !== influencerId));
        }
        catch (err) {
            console.log(err);
        }
    };

    const handleSubmit = async () => {
        try {
            const idsArray = listArr.map(obj => obj.id);
            let encryptData = await encryption({ listId: ID, tz: "Asia/Calcutta", userId: localStorage.getItem('userId'), influencerId: idsArray })
            let response = await api.post('/influencer/lists/add', { data: encryptData });

            if (response.status == 200) {
                setListArr([])
                let decryptData = await decryption(response.data);
                response.data = decryptData;

                navigate('/lists')
            }
            else if (response.status == 603) {
                setListArr([])
                localStorage.clear();
                navigate('/login')
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <Header setSearch={setSearch} />
            <main className='mt-[145px] xl:mt-[110px] 2xl:mt-[80px]  pt-[30px] px-[80px] h-[calc(100vh-145px)] xl:h-[calc(100vh-110px)] 2xl:h-[calc(100vh-80px)] w-full'>
                <div className=''>
                    <div className='mb-16'>
                        <Breadcrumb title={title} currentPage={[{ label: "Lists", class: null, link: '/lists' }, { label: "Influencer", class: "active", }]} />                        <div className='flex flex-wrap w-full gap-3 pb-[30px]'>
                            {
                                listArr.length > 0 ?
                                    // <InfiniteScroll
                                    //     dataLength={lists.length}
                                    //     next={fetchMoreData}
                                    //     hasMore={hasMore}
                                    // >
                                    <div className='flex flex-wrap w-full gap-3 pb-[30px]'>
                                        {
                                            listArr.map((info, index) => {
                                                return (
                                                    <ListInfluencerCard key={index} info={info} handleDeleteList={handleDeleteList} />
                                                )
                                            })
                                        }
                                    </div>
                                    // </InfiniteScroll>
                                    :
                                    <div className='flex justify-center items-center font-bold w-[80vw] h-[60vh]'>There are no influencers</div>
                            }
                        </div>
                    </div>
                </div>
                <footer className='fixed bottom-0 left-0 right-0 h-10 bg-black'>
                    <div className='flex justify-between'>
                        <div className='text-white text-lg mt-1 ml-[20px]'>Total : {listArr.length} influencers</div>
                        <div className='font-bold flex items-center justify-end bg-black text-white text-lg pr-4 pt-1 cursor-pointer'>
                            <button className='pr-2' type='submit' onClick={handleSubmit}>Submit</button>
                            <img src={whiteArrow} alt='save and add another one' className='w-4 h-4' />
                        </div>
                    </div>
                </footer>
            </main>
        </>
    )
}

export default AddInfluencers