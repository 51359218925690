import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
// import Home from './pages/Home';
import InfluencerForm from './pages/InfluencerForm';
import useScreenSize from './hooks/useScreenSize';
// import Model from './components/Model';
// import Users from './pages/Users';
import UserForm from './pages/UserForm';
// import ContentManagementSystem from './pages/ContentManagementSystem';
import NotFound from './pages/NotFound';
import CmsContent from './pages/CmsContent';
import CompanyList from './pages/CompanyList';
import ListOfInfluencer from './pages/ListOfInfluencer';
import ViewComments from './pages/ViewComments';
import InfluencerView from './pages/InfluencerView';
// import Lists from './pages';
// import Customer from './pages/Customer';
// import CustomerLists from './pages/CustomersLists';
// import LoginPage from './pages/LoginLandingPage';
import EditUser from './pages/EditUser';
import RightSidebar from './components/RightSidebar';
import ListHome from './pages/ListHome';
import { Suspense, lazy } from 'react';

import { MContext } from './context/MainContext';
import LoadingSpinner from './components/LoadingSpinner';
import AddInfluencers from './pages/AddInfluencers';
import ProtectedRoutes from './components/ProtectedRoutes';



// Lazy loading
const LoginPage = lazy(() => { return new Promise(resolve => { setTimeout(() => resolve(import("./pages/LoginLandingPage")), 3000); }); });
const Home = lazy(() => { return new Promise(resolve => { setTimeout(() => resolve(import("./pages/Home")), 1000); }); });
const Users = lazy(() => { return new Promise(resolve => { setTimeout(() => resolve(import("./pages/Users")), 1000); }); });
const ContentManagementSystem = lazy(() => { return new Promise(resolve => { setTimeout(() => resolve(import("./pages/ContentManagementSystem")), 1000); }); });
const CustomerLists = lazy(() => { return new Promise(resolve => { setTimeout(() => resolve(import("./pages/CustomersLists")), 1000); }); });
const Customer = lazy(() => { return new Promise(resolve => { setTimeout(() => resolve(import("./pages/Customer")), 1000); }); });


function App() {
  let screenSize = useScreenSize();
  // const location = useLocation();
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<LoadingSpinner />}>
          <MContext>
            <RightSidebar />
            <Routes>
              {/* <Route path="/" element={<Home />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/influencer/add" element={<InfluencerForm />} />
              <Route path="/add/influencers/:ID" element={<AddInfluencers />} />
              <Route path="/influencer/edit/:id" element={<InfluencerForm />} />
              <Route path="/influencer/:id" element={<InfluencerView />} />
              <Route path="/users" element={<Users />} />
              <Route path="/user/add" element={<UserForm />} />
              <Route path="/list/by/influencer/:id" element={<ListHome />} />
              <Route path="/user/:id" element={<EditUser />} />
              <Route path="/customers" element={<Customer />} />
              <Route path="/lists" element={<CustomerLists />} />
              <Route path="/company/lists/:id" element={<CustomerLists />} />
              <Route path="/CMS" element={<ContentManagementSystem />} />
              <Route path="/CMS/categories" element={<CmsContent />} />
              <Route path="/CMS/interests" element={<CmsContent />} />
              <Route path="/CMS/city" element={<CmsContent />} />
              <Route path="/CMS/country" element={<CmsContent />} />
              <Route path="/influencer/by/list/:id" element={<ListOfInfluencer />} />
              <Route path="/view/influencer/comments/:id" element={<ViewComments />} />
              <Route path="*" element={<NotFound />} /> */}
              <Route path="/" element={<ProtectedRoutes><Home /></ProtectedRoutes>} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/influencer/add" element={<ProtectedRoutes><InfluencerForm /></ProtectedRoutes>} />
              <Route path="/add/influencers/:ID" element={<ProtectedRoutes><AddInfluencers /></ProtectedRoutes>} />
              <Route path="/influencer/edit/:id" element={<ProtectedRoutes><InfluencerForm /></ProtectedRoutes>} />
              <Route path="/influencer/:id" element={<ProtectedRoutes><InfluencerView /></ProtectedRoutes>} />
              <Route path="/users" element={<ProtectedRoutes><Users /></ProtectedRoutes>} />
              <Route path="/user/add" element={<ProtectedRoutes><UserForm /></ProtectedRoutes>} />
              <Route path="/list/by/influencer/:id" element={<ProtectedRoutes><ListHome /></ProtectedRoutes>} />
              <Route path="/user/:id" element={<ProtectedRoutes><EditUser /></ProtectedRoutes>} />
              <Route path="/customers" element={<ProtectedRoutes><Customer /></ProtectedRoutes>} />
              <Route path="/lists" element={<ProtectedRoutes><CustomerLists /></ProtectedRoutes>} />
              <Route path="/company/lists/:id" element={<ProtectedRoutes><CustomerLists /></ProtectedRoutes>} />
              <Route path="/CMS" element={<ProtectedRoutes><ContentManagementSystem /></ProtectedRoutes>} />
              <Route path="/CMS/categories" element={<ProtectedRoutes><CmsContent /></ProtectedRoutes>} />
              <Route path="/CMS/interests" element={<ProtectedRoutes><CmsContent /></ProtectedRoutes>} />
              <Route path="/CMS/city" element={<ProtectedRoutes><CmsContent /></ProtectedRoutes>} />
              <Route path="/CMS/country" element={<ProtectedRoutes><CmsContent /></ProtectedRoutes>} />
              <Route path="/influencer/by/list/:id" element={<ProtectedRoutes><ListOfInfluencer /></ProtectedRoutes>} />
              <Route path="/view/influencer/comments/:id" element={<ProtectedRoutes><ViewComments /></ProtectedRoutes>} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </MContext>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
