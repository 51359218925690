import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Drawer } from 'antd';
import { CanvasContext } from '../context/MainContext';
import InfiniteScroll from "react-infinite-scroll-component";
import api from '../helpers/api';
import { useLocation, useNavigate } from 'react-router-dom';
import ListInfluencerCard from './ListInfluencerCard';
import RightSideBarCard from './RighSideBarCard';
import rightArrow from '../assets/rightArrow.svg'
import { decryption, encryption } from '../cipher/cipher';


const RightSidebar = () => {
    let { listId, setRightBar, rightBar, listArr, setListArr, setDataSource } = useContext(CanvasContext);

    let location = useLocation();

    let navigate = useNavigate();



    const onClose = () => {
        setRightBar(false);
    };

    // const handleInitialCards = async () => {
    //     try {
    //         let encryptData = await encryption({
    //             search: null,
    //             startDate: null,
    //             endDate: null,
    //             offset: 0,
    //             limit: 10,
    //             sort: 0,
    //             countryId: null,
    //             listId: (location.pathname).includes('/list/by/influencer/') ? Number((location.pathname).split('/').pop()) : null,
    //             cityId: null,
    //             categoryId: null,
    //             interestId: null,
    //             gender: null,
    //             instagram: null,
    //             tiktok: null,
    //             snapChat: null,
    //             x: null,
    //             youtube: null,
    //             ratings: null,
    //             userId: localStorage.getItem('userId')
    //         })
    //         let response = await api.post('/influencers', { data: encryptData })

    //         if (response.status == 200) {
    //             let decryptData = await decryption(response.data);
    //             response.data = decryptData;
    //             setCount(response.data.count);
    //             setDataSource(response.data.result)
    //         }

    //         if (response.status == 603) {
    //             localStorage.clear();
    //             navigate('/login')
    //         }
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // };


    // const fetchMoreData = async () => {
    //     try {
    //         page.current = page.current + 1;
    //         const newOffset = (page.current - 1) * 10;
    //         let encryptData = await encryption({
    //             search: null,
    //             startDate: null,
    //             endDate: null,
    //             offset: newOffset,
    //             limit: 10,
    //             sort: 0,
    //             countryId: null,
    //             listId: (location.pathname).includes('/list/by/influencer/') ? Number((location.pathname).split('/').pop()) : null,
    //             cityId: null,
    //             categoryId: null,
    //             interestId: null,
    //             gender: null,
    //             instagram: null,
    //             tiktok: null,
    //             snapChat: null,
    //             x: null,
    //             youtube: null,
    //             ratings: null,
    //             userId: localStorage.getItem('userId')
    //         })
    //         let response = await api.post('/influencers', { data: encryptData });

    //         if (response.status == 603) {
    //             localStorage.clear();
    //             navigate('/login')
    //         }

    //         let decryptData = await decryption(response.data);
    //         response.data = decryptData;

    //         /* Use the below logic  */
    //         if (response.data.result.length < 10) {

    //         }

    //         setTimeout(() => {
    //             setDataSource(dataSource.concat(response.data.result))
    //         }, 500);
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }

    // useEffect(() => {
    //     let currentLocation = location.pathname;
    //     if (rightBar) {
    //         window.scrollTo({ top: 0, behavior: 'smooth' });
    //         page.current = 1;
    //         handleInitialCards();
    //     }

    //     if (!rightBar) {
    //         listId.current = null;
    //     }

    // }, [rightBar]);

    // useEffect(() => {
    //     let currentLocation = location.pathname;
    //     if (rightBar) {
    //         window.scrollTo({ top: 0, behavior: 'smooth' });
    //         page.current = 1;
    //         handleInitialCards();
    //     }

    //     if (!rightBar) {
    //         listId.current = null;

    //     }

    // }, [location.pathname]);

    const handleNavigateList = () => {
        if (listArr.length) {
            setRightBar(false)
            navigate('/add/influencers/' + Number((location.pathname).split('/').pop()));
        }
    }

    const handleRemoveFromList = (id) => {
        if ((location.pathname).includes('/list/by/influencer/')) {
            setListArr(prevItems => prevItems.filter(item => item.id !== id));
            setDataSource(prevVal =>
                prevVal.map(item => {
                    if (item.id === id) {
                        return { ...item, listSummaryStatus: false }; // Return a new object with the updated status
                    }
                    return item; // Return the item unchanged
                })
            );

        } else {
            return;
        }
    }

    return (
        <>
            <Drawer
                id='drawer'
                title="List Summary"
                placement={'right'}
                onClose={onClose}
                open={rightBar}
                width={450}
                className='relative'
            >
                {/* <InfiniteScroll
                    dataLength={dataSource.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    height={1000}
                    className='[&::-webkit-scrollbar]:hidden'
                > */}
                <div className='flex flex-wrap w-full gap-3 pb-[30px]'>
                    {
                        listArr.map((info, index) => {
                            return (
                                <RightSideBarCard key={index} info={info} handleRemoveFromList={handleRemoveFromList} />
                            )
                        })
                    }
                </div>
                {/* </InfiniteScroll> */}
                {/* </div> */}
                {
                    (location.pathname).includes('/list/by/influencer/') &&
                    <div className='absolute bottom-0 right-0 h-12 bg-black w-full text-white flex items-center justify-between'>
                        <div className='font-bold text-[18px] ml-[20px]'>Total: {listArr.length} influencer's</div>
                        <div className='flex  mr-[10px] cursor-pointer' onClick={handleNavigateList}>
                            <div className='font-bold text-[18px] ml-[20px] pr-[15px]'>View list</div>
                            <img alt='right-arrow' src={rightArrow} />
                        </div>
                    </div>
                }

            </Drawer>
        </>
    );
};

export default RightSidebar;