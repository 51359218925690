import React, { useEffect, useState, useRef } from 'react'
import Breadcrumb from '../components/Breadcrumb'
import ListCard from '../components/ListCard'
import ListInfluencerCard from '../components/ListInfluencerCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import api from '../helpers/api';
import { decryption, encryption } from '../cipher/cipher';


const CompanyList = () => {

    let navigate = useNavigate();

    let { id } = useParams()
    let [search, setSearch] = useState();
    const [hasMore, setHasMore] = useState(true);
    let location = useLocation();

    let page = useRef();

    let [title, setTitle] = useState('');
    let [lists, setLists] = useState([]);



    const handleInitialList = async () => {
        try {
            let request = {
                listId: id,
                search: search ? search : null,
                userId: localStorage.getItem('userId'),
                offset: 0,
                limit: 10
            };

            let encryptData = await encryption(request);
            
            let response = await api.post('/influencer/list/search', { data: encryptData });

            if (response.status == 200) {
                let decryptData = await decryption(response.data);
                
                response.data = decryptData;
                setLists(response.data.result)
            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        const getTitle = async () => {
            try {
                let encryptData = await encryption({ listId: id, userId: localStorage.getItem('userId') })
                let response = await api.post('/customer/list', { data: encryptData });

                if (response.status == 200) {
                    let decryptData = await decryption(response.data);
                    response.data = decryptData;
                    setTitle(response.data.companyName + ', ' + response.data.listName)
                }
                else if (response.status == 603) {
                    localStorage.clear();
                    navigate('/login');
                }
            }
            catch (err) {
                console.log(err);

            }
        }
        getTitle()
        handleInitialList();

    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        page.current = 1;
        handleInitialList();
    }, [search])

    const fetchMoreData = async () => {
        page.current = page.current + 1;
        const newOffset = (page.current - 1) * 10;

        let encryptData = await encryption({
            listId: id,
            search: search ? search : null,
            userId: localStorage.getItem('userId'),
            offset: newOffset,
            limit: 10
        })
        let response = await api.post('/customers', { data: encryptData });


        if (response.status == 603) {
            localStorage.clear();
            navigate('/login')
        }

        let decryptData = await decryption(response.data);
        
        response.data = decryptData;

        /* Use the below logic  */
        if (response.data.result.length < 10) {

        }

        setTimeout(() => {
            setLists(lists.concat(response.data.result))
        }, 500);
    }

    const handleDeleteList = async (influencerId) => {
        try {
            let request = {
                influencerId: influencerId,
                listId: id,
                userId: localStorage.getItem('userId')
            }

            let encryptData = await encryption(request)
            let response = await api.post('/influencer/list/delete', { data: encryptData });


            if (response.status == 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                window.scrollTo({ top: 0, behavior: 'smooth' });
                page.current = 1;
                handleInitialList();
            } else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <Header setSearch={setSearch} />
            <main className='mt-[145px] xl:mt-[110px] 2xl:mt-[80px]  pt-[30px] px-[80px] h-[calc(100vh-145px)] xl:h-[calc(100vh-110px)] 2xl:h-[calc(100vh-80px)] w-full'>
                {/* <div className='flex justify-center items-center'> */}
                <div className='mb-16'>
                    <Breadcrumb title={title ? title : "company's name, list name"} currentPage={[{ label: "Customers", class: null, link: '/customer' }, { label: "List", class: null, link: '/lists' }, { label: 'Influencer', class: 'active' }]} />
                    {/* <div className='flex flex-wrap w-full gap-3 pb-[30px]'> */}
                    {
                        lists.length > 0 ?
                            <div className='h-[700px]'>
                                <InfiniteScroll
                                    dataLength={lists.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    height={700}
                                    className='[&::-webkit-scrollbar]:[width:3px] '
                                >
                                    <div className='flex flex-wrap gap-3 pb-[30px]'>
                                        {
                                            lists.map((info, index) => {
                                                return (
                                                    <ListInfluencerCard key={index} info={info} handleDeleteList={handleDeleteList} />
                                                )
                                            })
                                        }
                                    </div>
                                </InfiniteScroll>
                            </div>

                            :
                            <div className='flex justify-center items-center font-bold w-[100%] h-[60vh]'>There are no influencers</div>
                    }
                </div>
                {/* </div> */}
                {/* </div> */}
            </main>
        </>
    )
}

export default CompanyList