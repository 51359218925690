import React, { useEffect, useRef, useState } from 'react';
import closeSvg from '../assets/close.svg';
import { useFormik } from 'formik';
import { number, object, string } from 'yup';

import listSvg from '../assets/list.svg';
import api from '../helpers/api';
import { useNavigate } from 'react-router-dom';
import { decryption, encryption } from '../cipher/cipher';



const ListForm = ({ setListModelStatus, listModelStatus }) => {

    const [companySelect, setCompanySelect] = useState('default-value');
    const [company, setCompany] = useState([]);

    let navigate = useNavigate();

    // let company = [
    //     { id: 1, name: 'ahsan solutions' },
    //     { id: 2, name: 'text+ solutions' },
    // ]

    useEffect(() => {

        const handleCompanyList = async () => {
            try {
                let encryptData = await encryption({ userId: localStorage.getItem('userId') })
                let response = await api.post('/customers/list', { data: encryptData });

                if (response.status == 200) {
                    let decryptData = await decryption(response.data);
                    response.data = decryptData;
                    setCompany(response.data)
                }
                else if (response.status == 603) {
                    localStorage.clear();
                    navigate('/login')
                }
            }
            catch (err) {
                console.log(err);
            }
        };

        handleCompanyList();

    }, [listModelStatus]);

    const companySchema = object().shape({
        companyId: number()
            .required('required'),
        listName:
            string()
                .required('required')
                .max(30, 'Must be 30 characters or less')
                .matches(/^[A-Za-z\s]+$/, 'must be only contain letters and spaces')
                .test(
                    'test-id',
                    'This name was already exist',
                    async function validateValue(value) {
                        try {
                            let request = {
                                tableName: "list",
                                columnName: "name",
                                value: value,
                                id: null,
                                userId: localStorage.getItem('userId')
                            };
                            let encryptData = await encryption(request)
                            let response = await api.post('/unique/check', { data: encryptData })

                            if (response.status == 606)
                                return false;
                            else {
                                let decryptData = await decryption(response.data);
                                response.data = decryptData;
                                return true
                            }
                        } catch (error) {

                        }
                    }),
    });

    const handleListSubmit = async (values) => {
        try {
            let encryptData = await encryption({ name: values.listName, status: 0, customerId: values.companyId, userId: localStorage.getItem('userId'), tz: "Asia/Calcutta" })
            let response = await api.post('/list/add', { data: encryptData });

            if (response.status == 200) {
                let decryptData = await decryption(response.data);
                response.data = decryptData;
                setListModelStatus(!listModelStatus);
                setCompanySelect('default-value')
                formik.resetForm()
            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/login')
            }
        }
        catch (err) {
            console.log(err);
        }
    }


    const formik = useFormik({
        initialValues: {
            companyId: null,
            listName: ''
        },
        validationSchema: companySchema,
        onSubmit: values => {
            handleListSubmit(values);
        },
    });

    return (
        <div className='pt-12'>
            <div className='flex justify-center items-center flex-col'>
                <img src={listSvg} />
                <div className='pt-2 font-bold'>Create a new list</div>
                <form onSubmit={formik.handleSubmit} className='w-[80%]'>
                    <div className='mt-4'>
                        <label>Select customer</label>
                        <select value={companySelect} id="countries" name='companyId' className="bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none" onChange={(e) => {
                            setCompanySelect(e.target.value)
                            formik.setFieldValue('companyId', Number(e.target.value));
                        }}>
                            <option value={'default-value'} selected hidden>Select customer</option>
                            {
                                company.map((item, index) =>
                                    <option key={index} value={item.id}>{item.companyName}</option>
                                )
                            }
                        </select>
                        <span className={`${formik.errors.companyId ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.companyId ? formik.errors.companyId : '\u00A0'}</span>
                    </div>
                    <div className='mt-0'>
                        <label>List name</label>
                        <input type="text" name='listName' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' placeholder="Type the list name here..." onChange={formik.handleChange} value={formik.values.listName} />
                        <span className={`${formik.errors.listName && formik.touched.listName ? 'text-[#FF0000]' : 'text-[#ffffff]'} text-[12px]`}>{formik.errors.listName && formik.touched.listName ? formik.errors.listName : '\u00A0'}</span>
                    </div>
                    <div className='mt-1 flex justify-center'>
                        <button type="submit" className="text-white h-[38px] w-[183px] bg-black border border-[#000000] hover:bg-black font-medium rounded-3xl text-sm">Create a list</button>
                    </div>
                </form>
            </div >
            <img src={closeSvg} alt='close-btn' className='absolute top-[20px] left-[20px] cursor-pointer' onClick={() => {
                setListModelStatus(!listModelStatus);
                setCompanySelect('default-value')
                formik.resetForm()
            }
            } />
        </div >
    )
}

export default ListForm;