import React, { useState } from 'react';
import noImg from '../assets/no-img.svg';
import user from '../assets/user.svg';
import star from '../assets/star.svg';
import interest from '../assets/interest.svg';

// Social media icons

import youtube from '../assets/socialMedia/youtube.svg';
import instagram from '../assets/socialMedia/instagram.svg';
import tikTok from '../assets/socialMedia/tikTok.svg';
import snapchat from '../assets/socialMedia/snapchat.svg';
import x from '../assets/socialMedia/x.svg';
import eye from '../assets/eye.svg';
import grayEye from '../assets/grayEye.svg';
import remove from '../assets/removeIcon.svg';

// CTA buttons
import comments from '../assets/socialMedia/comments.svg';
import add from '../assets/socialMedia/add.svg';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import AddToListForm from './AddToListForm';
import api from '../helpers/api';
import { decryption, encryption } from '../cipher/cipher';



const SelectInfluencerCard = ({ info, id, handleAddInfluencerToList }) => {

    const [listModelStatus, setListModelStatus] = useState(false);

    const privileges = JSON.parse(localStorage.getItem('privileges')) || [];


    let navigate = useNavigate();

    const handleNavigate = () => {
        if (privileges.includes('influencerEdit')) {
            navigate('/influencer/' + info?.id);
        }
    };

    const handleAddList = async () => {
        if ('listSummaryStatus' in info) {
            info.listSummaryStatus = !(info.listSummaryStatus)
        }
        else {
            info.listSummaryStatus = true
        }
        handleAddInfluencerToList(info)
    }

    const handleViewComments = () => {
        navigate('/view/influencer/comments/' + info.id);
    }

    return (
        <>
            <div className='w-[400px] h-[470px] border border-black rounded-3xl p-4 py-3 '>
                {/* Profile-info */}
                <div className='flex gap-4 mb-1 items-start cursor-pointer'>
                    <div className='w-[163px] h-[161px] border border-transparent rounded-3xl'>
                        <img src={info?.imageUrl ? global.config.API_URL + info?.imageUrl : noImg} alt='influencer-profile' className=' w-[163px] rounded-2xl h-[161px] object-cover' />
                    </div>
                    <div>
                        <p className='pt-0 font-bold'>{(info?.fullName) && (info?.fullName).length > 10 ? info?.fullName.slice(0, 10) + '...' : info?.fullName}</p>
                        <p className='pt-0 text-[13px]'>{info?.countryName}</p>
                        <p className='pt-0 text-[13px]'>{info?.cityName}</p>

                        <div className='flex items-center gap-2 pt-2 text-[13px]'>
                            <img src={user} alt='gender-icon' />
                            <p>{info.gender == 0 ? 'Male' : 'Female'}</p>
                        </div>
                        <div className='flex items-center gap-2 text-[13px]'>
                            <img src={star} alt='gender-icon' />
                            <p>Rating: {info?.average ? info?.average : 0}</p>
                        </div>
                        <div className='flex items-start gap-2 text-[13px]'>
                            <img src={interest} alt='gender-icon' className='pt-[5px]' />
                            <p>{(info?.interestName) && (info?.interestName).length > 15 ? info?.interestName.slice(0, 15) + '...' : info?.interestName}</p>
                        </div>
                    </div>
                </div>

                {/* Social media info */}
                <div>
                    <div className='flex justify-between items-center bg-[#FAFAFA] p-1 px-3 rounded-xl mb-1'>
                        <div className='flex items-center gap-3'>
                            <img src={instagram} className='w-9 h-9' alt='instagram' />
                            Not available
                        </div>
                        <p>{(info?.instagramCount) ? info?.instagramCount : 'Not available'}</p>
                        {
                            (info?.instagram) ?
                                <img src={eye} className='cursor-pointer w-[18px] h-[18px]' onClick={() => window.open(info?.instagram, '_blank')} />
                                :
                                <img src={grayEye} className='cursor-default w-[18px] h-[18px]' />
                        }
                    </div>

                    <div className='flex justify-between items-center bg-[#FAFAFA] p-1 px-3 rounded-xl mb-1'>
                        <div className='flex items-center gap-3'>
                            <img src={tikTok} className='w-9 h-9' alt='instagram' />
                            {info?.tiktokUserName ? info?.tiktokUserName.length > 10 ? info?.tiktokUserName.slice(0, 10) + '...' : info?.tiktokUserName : 'Not available'}
                        </div>
                        <p>{(info?.tiktokCount) ? info?.tiktokCount : 'Not available'}</p>
                        {
                            (info?.tiktok) ?
                                <img src={eye} className='cursor-pointer w-[18px] h-[18px]' onClick={() => window.open(info?.tiktok, '_blank')} />
                                :
                                <img src={grayEye} className='cursor-default w-[18px] h-[18px]' />
                        }
                    </div>

                    <div className='flex justify-between items-center bg-[#FAFAFA] p-1 px-3 rounded-xl mb-1'>
                        <div className='flex items-center gap-3'>
                            <img src={snapchat} className='w-9 h-9' alt='instagram' />
                            {info?.snapchatUserName ? info?.snapchatUserName.length > 10 ? info?.snapchatUserName.slice(0, 10) + '...' : info?.snapchatUserName : 'Not available'}
                        </div>
                        <p>{(info?.snapchatCount) ? info?.snapchatCount : 'Not available'}</p>
                        {
                            (info?.snapchat) ?
                                <img src={eye} className='cursor-pointer' onClick={() => window.open(info?.snapchat, '_blank')} />
                                :
                                <img src={grayEye} className='cursor-default' />
                        }
                    </div>

                    <div className='flex justify-between items-center bg-[#FAFAFA] p-1 px-3 rounded-xl mb-1'>
                        <div className='flex items-center gap-3'>
                            <img src={x} className='w-9 h-9' alt='instagram' />
                            Not available
                        </div>
                        <p>{(info?.xCount) ? info?.xCount : 'Not available'}</p>
                        {
                            (info?.x) ?
                                <img src={eye} className='cursor-pointer w-[18px] h-[18px]' onClick={() => window.open(info?.x, '_blank')} />
                                :
                                <img src={grayEye} className='cursor-default' />
                        }
                    </div>

                    <div className='flex justify-between items-center bg-[#FAFAFA] p-1 px-3 rounded-xl mb-1'>
                        <div className='flex items-center gap-3'>
                            <img src={youtube} className='w-9 h-9' alt='instagram' />
                            {info?.youtubeUserName ? info?.youtubeUserName.length > 10 ? info?.youtubeUserName.slice(0, 10) + '...' : info?.youtubeUserName : 'Not available'}
                        </div>
                        <p>{(info?.youtubeCount) ? info?.youtubeCount : 'Not available'}</p>
                        {
                            (info?.youtube) ?
                                <img src={eye} className='cursor-pointer w-[18px] h-[18px]' onClick={() => window.open(info?.youtube, '_blank')} />
                                :
                                <img src={grayEye} className='cursor-default w-[18px] h-[18px]' />
                        }
                    </div>

                    {/* Comments & Add to list */}
                    <div className='flex justify-between items-center'>
                        {
                            (privileges.includes('comments')) &&
                            <button type="button" className="text-black w-[175px] h-[38px] mt-[3px] bg-[#EAEAEA] border border-[#000000] font-medium rounded-lg text-sm flex items-center justify-between p-4" onClick={() => handleViewComments()}>
                                <p>View comments</p>
                                <img src={comments} alt='view-comments' className='mt-[6px]' />
                            </button>
                        }
                        {
                            (privileges.includes('influencerListAdd')) &&

                            <button type="button" className="text-black w-[175px] h-[38px] bg-[#EAEAEA] border border-[#000000] font-medium rounded-lg text-sm flex items-center justify-between p-4" onClick={() => handleAddList()}>
                                {
                                    info?.listSummaryStatus ?
                                        <>
                                            <p>Remove</p>
                                            <img src={remove} alt='remove-list' />
                                        </>
                                        :
                                        <>
                                            <p>Add to list</p>
                                            <img src={add} alt='add-to-list' />
                                        </>
                                }
                            </button>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectInfluencerCard