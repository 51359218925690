import React from 'react';
import { ProgressBar } from 'react-loader-spinner';


const LoadingSpinner = () => {
    return (
        <div className='w-screen h-screen flex justify-center items-center flex-col'>
            <ProgressBar
                visible={true}
                height="100"
                width="100"
                color="#F15625"
                barColor="#F15625"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )
}

export default LoadingSpinner;